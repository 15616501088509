import React from "react";
import styled from "styled-components";
import { CompanyInfoObj } from "../constants";

const CompanyInfoCont = styled.address`
  p {
    font-size: 1.6rem;
    font-weight: var(--fw_medium);

    word-break: keep-all;
  }
`;

function CompanyInfo({className}) {

  return (
    <CompanyInfoCont className={className}>
      <ul>
        <li>
          <p className="office">
            <span>Office</span>
          </p>
          <p>
            <span>{CompanyInfoObj.address}</span>
          </p>
        </li>
        <li>
          <p>
            <span>Tel.&nbsp;{CompanyInfoObj.tel}</span>
          </p>
          <p>
            <span>Email.&nbsp;{CompanyInfoObj.email}</span>
          </p>
        </li>
      </ul>
    </CompanyInfoCont>
  );
}

export default CompanyInfo;
