import React from "react";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import ContactUsButton from "./button/ContactUsButton";
import CompanyInfo from "./CompanyInfo";
import { responsiveFontMixin } from "../styles/mixins";
import {API_ROUTES, HOST_NAME, TABLET_WIDTH} from "../constants";

const NAV_BACKGROUND_COLOR = '#111';

const NavbarCont = styled.aside`
  position: fixed;
  z-index: var(--navbar_index);
  top: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 0;
  height: 0;
  min-height: 600px;
  padding: 0 4.7% 4.7% 4.2%;
  overflow: hidden;
  transition-delay: 0.8s;
  visibility: hidden;

  &::before {
    content: '';
    position: fixed;
    z-index: -1;
    top: calc(var(--header)/2);
    right: 5.2%;
    width: 300vw;
    height: 300vh;
    background: ${NAV_BACKGROUND_COLOR};
    border-radius: 50%;
    transform: translate(50%, -50%) scale(0);
    transition: 0.5s;
    transition-delay: 0.2s;
  }

  &.on {
    width: 100vw;
    height: calc(var(--vh) * 100);
    visibility: visible;
    transition-delay: 0s;

    &::before {
      transform: translate(50%, -50%) scale(1);
      transition-delay: 0s;
    }

    address {
      ul {
        li {
          p {
            span {
              transform: translateY(0);
              transition-delay: 0.3s;
            }
            
            &:nth-child(2) {
              span {
                transition-delay: 0.35s;
              }
            }
          }

          &:nth-child(2) {
            p {
              span {
                transition-delay: 0.4s;
              }

              &:nth-child(2) {
                span {
                  transition-delay: 0.45s;
                }
              }
            }
          }
        }
      }
    }

    .nav-item {
      &.on::before {
        opacity: 1;
        transition-delay: 0.6s;  
      }

      .nav-links span {
        transform: translateY(0);
        transition-delay: 0.2s;
        transition-property: transform;
      }

      &:nth-child(2){
        .nav-links span {
          transition-delay: 0.25s;
        }
      }
      &:nth-child(3){
        .nav-links span {
          transition-delay: 0.3s;
        }
      }
      &:nth-child(4){
        .nav-links span {
          transition-delay: 0.35s;
        }
      }
    }
    

    .button_wrap {
      > div {
        button {
          transform: translateY(0);
          transition-delay: 0.1s;
        }

        &:nth-child(2) {
          button {
            transition-delay: 0.2s;
          }
        }
      }
    }

    .mobile_bottom_text {
      span {
        transform: translateY(0);
        transition-delay: 0.4s;
      }
    }
  }

  address {
    ul {
      li {
        p {
          overflow: hidden;

          span {
            display: inline-block;
            transform: translateY(110%);
            transition: 0.5s;
          }
        }
      }
    }
  }

  .nav-ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 4.1% 17%;

    li {
      position: relative;
      font-size: 0;
      transition: 0.5s;

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 130.9%;
        height: 143.8%;
        border: 1px solid transparent;
        border-radius: 50%;
        background-image: linear-gradient(#111, #111),
        linear-gradient(to right, #D6E2FF 0%, #4D7EFF 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        opacity: 0;
        transition: opacity 0.5s;
        transition-delay: 0s;
      }

      &.nav_experience::before {
        width: 116.7%;
        height: 144%;
      }

      &:nth-child(n+2) {
        margin-top: 14.1%;
      }

      &:hover {
        a {
          color: var(--main1);
        }
      }

      &.on {
        .nav-links {
          color: var(--main1);
        }
      }

      .nav-links {
        position: relative;
        ${responsiveFontMixin(96, 46, 1920, 360)};
        font-weight: var(--fw_medium);
        color: var(--gray1);
        transition: var(--trans_time);
        overflow: hidden;

        span {
          display: inline-block;
          transform: translateY(110%);
          transition: 0.5s;
        }
      }
    }
  }

  .button_wrap {
    display: flex;
    flex-direction: column;

    > div {
      overflow: hidden;

      &:nth-child(n+2) {
        margin-top: 20px;
      }

      button {
        transform: translateY(110%);
        transition: 0.5s;
      }
    }
  }

  .mobile_bottom_text {
    opacity: 0;
    visibility: hidden;
    margin-top: 31px;
    font-size: 11px;
    color: #949494;
    text-align: center;
    overflow: hidden;

    span {
      display: inline-block;
      transform: translateY(110%);
      transition: 0.3s;
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    padding: 114px 0 40px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .nav-ul {
      padding: 0;

      li {
        font-weight: var(--fw_bold);

        &:nth-child(n+2) {
          margin-top: 40px;
        }
      }
    }

    .button_wrap {
      > button {
        &:nth-child(n+2) {
          margin-top: 16px;
        }
      }
    }

    .mobile_bottom_text {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const NavbarCompanyInfoCont = styled(CompanyInfo)`
  padding-bottom: 1.7%;

  li {
    &:nth-child(n+2) {
      margin-top: 38px;
    }

    p {
      &:nth-child(n+2) {
        margin-top: 14px;
      }
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    display: none;
  }
`;

const StyledContactUsButton = styled(ContactUsButton)`
  img {
    margin: 0 4px 0 -10px;
  }
`;

const Navbar = ({className, location, onClick}) => {
  const handleClick = () => onClick(false);

  const handleBrochureButtonClick = () => {
    window.open(HOST_NAME + API_ROUTES.downloadBrochure);
  }

  return (
    <NavbarCont className={className}>
      <NavbarCompanyInfoCont/>

      <ul className="nav-ul">
        <li
          className={`nav-item ${location === "about" ? "on" : ""}`}
        >
          <NavLink
            to="/about/"
            className={`nav-links`}
            onClick={handleClick}
          >
            <span>About</span>
          </NavLink>
        </li>

        <li
          className={`nav-item nav_experience ${location === "experience" ? "on" : ""}`}
        >
          <NavLink
            to="/experience/?category=All"
            className={`nav-links`}
            onClick={handleClick}
          >
            <span>Experience</span>
          </NavLink>
        </li>

        <li
          className={`nav-item ${location === "recruit" ? "on" : ""}`}
        >
          <NavLink
            to="/recruit/"
            className={`nav-links`}
            onClick={handleClick}
          >
            <span>Recruit</span>
          </NavLink>
        </li>

        <li
          className={`nav-item ${location === "request" ? "on" : ""}`}
        >
          <NavLink
            to="/request"
            className={`nav-links`}
            onClick={handleClick}
          >
            <span>Contact</span>
          </NavLink>
        </li>
      </ul>
      <div className="button_wrap">
        <div>
          <StyledContactUsButton
            onClickFunc={handleBrochureButtonClick}
          >
            <img src="/img/download.svg" alt="다운로드 아이콘" />Brochure
          </StyledContactUsButton>
        </div>
        <p className="mobile_bottom_text">
          <span>Beyond your expectation</span>
        </p>
      </div>
    </NavbarCont>
  );
}

export default Navbar;
