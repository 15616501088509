import {createBrowserRouter, RouterProvider,} from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Recruit from "./pages/Recruit";
import Request from "./pages/Request";
import Experience from "./pages/experience/Experience";

import GlobalStyle, { setScreenHeight } from "./styles/GlobalStyle";
import Detail from "./pages/experience/Detail";
import Root from "./pages/Root";
import { useCallback, useEffect } from "react";
import { debounce } from "lodash";

function App() {
    // *** --vh 정의 *** //
    const debSetScreenHeight = useCallback(
        debounce(() => {
            setScreenHeight();
        }, 300),
        [],
    );

    useEffect(() => {
        debSetScreenHeight();
        window.addEventListener('resize', debSetScreenHeight);
        return () => window.removeEventListener('resize', debSetScreenHeight);
    }, []);
    // *** --vh 정의 *** //

    // gtm
    window.dataLayer = window.dataLayer || [];
    function gtag(){
        window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-ED3C35QGK9');
    
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Root />,
            children: [
                {
                    index: "/",
                    element: <Home />,
                },
                {
                    path: "about",
                    element: <About />,
                },
                {
                    path: "experience",
                    element: <Experience />,
                },
                {
                    path: "recruit",
                    element: <Recruit />,
                },
                {
                    path: "contact",
                    element: <Contact />,
                },
                {
                    path: "request",
                    element: <Request gtag={gtag} />,
                },
                {
                    path: "experience/detail/:id",
                    element: <Detail />,
                },
            ],
        },
    ]);
    
    return (
        <>
            <GlobalStyle/>
            {/* <GoogleTagManager gtmId="AW-11303882783" /> */}
            <RouterProvider router={router} gtag={gtag} />
        </>
    );
}

export default App;
