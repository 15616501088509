import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { INPUT_HEIGHT, INPUT_HEIGHT_MOBILE, MOBILE_WIDTH } from "../../constants";
import Button from "../button/Button";
import { ellipsisMixin } from "../../styles/mixins";

const InputFileCont = styled.div`
  .file_upload_notice {
    margin-bottom: 4rem;
    font-size: 1.6rem;
    font-weight: var(--fw_medium);
    color: var(--gray5);
    line-height: 1.7;
    
    &.mo {
      display: none;
    }
  }

  .file_list {
    position: relative;
    background: var(--black3) url(/img/upload.svg) right 0.6rem center/4.8rem no-repeat;
    cursor: pointer;

    &.on {
      background-image: none;

      .file_remove_button {
        display: block;
      }
    }

    .file_item {
      max-width: calc(100% - 60px);
      height: ${INPUT_HEIGHT};
      line-height: ${INPUT_HEIGHT};
      font-size: 1.8rem;
      padding: 0 0 0 1.6rem;

      &.file_item_placeholder {
        flex: 1 1 100%;
        max-width: none;
        color: var(--gray1);
      }

      &:nth-child(n+2) {
        margin-left: 2.6rem;
      }

      p {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        ${ellipsisMixin('100%')};
      }
    }

    .file_remove_button {
      display: none;
      position: absolute;
      top: 50%;
      right: 0.6rem;
      transform: translateY(-50%);
      width: 4.8rem;
      border: none;
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    .file_upload_notice {
      margin-bottom: 16px;
      font-size: 11px;
      line-height: 1.7;

      &.pc {
        display: none;
      }

      &.mo {
        display: block;
      }

      span {
        display: none;
      }
    }

    .file_list {
      background-size: 34px;
      background-position: right 8px center;
      transform: scale(0.8125);
      transform-origin: left top;
      width: 123.07% !important;
      margin-right: -23.07%;
      margin-bottom: -11px;

      .file_item {
        height: ${INPUT_HEIGHT_MOBILE};
        line-height: ${INPUT_HEIGHT_MOBILE};
        font-size: 16px;
        padding: 0 20px;
      }

      .file_remove_button {
        right: 8px;
        width: 34px;
        padding: 8px 0;
      }
    }
  }
`;

const InputFile = React.memo(({
    id,
    className,
    placeholder = 'Upload',
    onChange = () => {},
    sizeLimit = 50,
    numLimit = 1,
    initFile,
    setInitFile,
  }) => {
    const [fileName, setFileName] = useState([]);

    const fileInputRef = useRef(null);

    let filesArr = [];

    const handleUpload = () => {
      fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
      const files = fileInputRef.current.files;
      if(files.length > 0) {
        if (files.length > numLimit) {
            alert(`파일은 최대 ${numLimit}개까지 업로드 가능합니다.`);
            fileInputRef.current.value = '';
            return;
        }
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
            if(file.size > sizeLimit * 1024 * 1024) {
              alert(`${sizeLimit}MB 이하의 파일을 업로드해 주세요.`);
              fileInputRef.current.value = '';
              filesArr = [];
              break;
            } else {
              filesArr.push(file.name);
            }
        }
        setFileName(filesArr);
        onChange(files);
      }
    };

    const handleRemoveFile = () => {
      fileInputRef.current.value = '';
      setFileName([]);
    };

    useEffect(() => {
      if (initFile) {
        handleRemoveFile();
        onChange(null);
        setInitFile(false);
      }
    }, [initFile]);

    return (
      <InputFileCont
        className={className}
      >
        <input
          type="file"
          ref={fileInputRef}
          id={id}
          className="layout-hidden"
          accept={".pdf,.ppt,.word,.xlsx,.jpg,.png,.zip"}
          onChange={handleFileChange}
          multiple={numLimit > 1}
        />
        <p className="file_upload_notice pc">
          * 최대 {numLimit}개 업로드 가능 | pdf, ppt, word, xlsx, jpg, png, zip | 최대 {sizeLimit}MB
        </p>
        <p className="file_upload_notice mo">
          * 최대 {numLimit}개 업로드 가능 | 최대 {sizeLimit}MB |<br/>pdf, ppt, word, xlsx, jpg, png, zip
        </p>
        <div
          className={`file_list ${!!fileName.length ? 'on' : ''}`}
        >
          {!!fileName.length ? (
            fileName.map((item, index) => (
              <div
                key={item}
                className={`file_item file_item_${index}`}
              >
                <p
                  onClick={handleUpload}
                >
                  {item}
                </p>
              </div>
            ))
          ) : (
            <div
              className="file_item file_item_placeholder"
              onClick={handleUpload}
            >
              {placeholder}
            </div>
          )}
          <Button
            className="file_remove_button"
            onClick={handleRemoveFile}
          >
            <img src="/img/x_maincolor_48.svg" alt="엑스 아이콘" />
          </Button>
        </div>
      </InputFileCont>
    );
  }
);

export default InputFile;