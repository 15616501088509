import React, {useEffect, useMemo, useRef} from "react";
import styled, { keyframes } from "styled-components";
import { MOBILE_WIDTH, TABLET_WIDTH } from "../constants";
import {NavLink, useLocation} from "react-router-dom";
import {GetExperienceListInfinity} from "../apis";

const ExperienceListCont = styled.div`
  .experience_list {
    display: flex;
    flex-wrap: wrap;
    min-height: 31.2vw;

    .loading_text {
      flex: 1 1 100%;
      height: 100vh;
      padding-top: 20rem;
      font-size: 3.6rem;
      font-weight: var(--fw_medium);
      text-align: center;
    }
  }
`;

const AniItemShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`;

const ExperienceCard = styled(NavLink)`
  position: relative;
  flex: 0 0 50%;
  height: 31.2vw;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${props => props.bgimg || ''});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity var(--trans_time);
  animation: ${AniItemShow} 0.5s;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    transition: opacity var(--trans_time);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: 1px solid var(--main1);
    border-radius: 50%;
    opacity: 0;
    transition: opacity var(--trans_time);
  }

  .card_info {
    position: relative;
    flex: 0 0 auto;
    text-align: center;
    opacity: 0.6;
    transition: opacity var(--trans_time);

    & > p {
      color: var(--main1);
    }

    .company_title {
      position: relative;
      padding-bottom: 9px;
      text-align: center;

      img {
        width: 22rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 28px;
        height: 3px;
        background: var(--main1);
      }
    }

    .company_info {
      margin-top: 12px;
      font-size: 1.8rem;
      font-weight: var(--fw_medium);
    }

    .work_info {
      margin-top: 6px;
      font-size: 1.4rem;
      font-weight: var(--fw_thin);
    }

    .work_date {
      margin-top: 12px;
      font-size: 1.3rem;
      font-weight: var(--fw_medium);
    }
  }

  &:hover {
    opacity: 1;

    .card_info {
      opacity: 1;
    }

    &::before {
      opacity: 0.2;
    }

    &::after {
      opacity: 1;
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px){

  }

  @media screen and (max-width: ${MOBILE_WIDTH}px){
    flex: 1 1 100%;
    height: 64vw;

    .card_info {
      .company_title {
        padding-bottom: 2px;

        &::after {
          height: 2px;
        }

        img {
          width: 100px;
        }
      }

      .company_info {
        margin-top: 8px;
        font-size: 12px;
      }

      .work_info {
        font-size: 12px;
      }

      .work_date {
        margin-top: 10px;
        font-size: 12px;
      }
    }
  }
`;

function ExperienceList() {
    const location = useLocation();
    const category = location.search.split("=")[1];
    const option = category === "All" ? {} : { query: { category: category } };
    useEffect(() => {
        setSize(1);
    }, [category]);
    const { data, isError, isLoading, size, setSize } = GetExperienceListInfinity("getExperience", option);

    const experienceList = useMemo(() => {
        if (!data) return [];
        return data.flat(1);
    }, [data]);

    let pageEnd = useRef();
    useEffect(() => {
        if (pageEnd.current && Number(pageEnd.current.id)+1 === experienceList.length) {
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && experienceList.length / 8 === size) {
                    setSize(size + 1);
                }
            }, {
                threshold: 0.5,
            })
            observer.observe(pageEnd.current);
        }
    }, [pageEnd, data, category]);

  return (
    <ExperienceListCont>
      <div className="experience_list">
        {isLoading ? <p className="loading_text"></p> :
          experienceList.map((experience, index, array) => {
            return (
              <ExperienceCard
                  key={experience.id}
                  to={`/experience/detail/${experience.id}`}
                  bgimg={experience.listimg}
              >
                <div className="card_info" id={index} ref={(index + 1 === array.length) ? pageEnd : null}>
                  <p className="company_title"><img src={experience.logoimg} alt={experience.title}/></p>
                  <p className="company_info">{experience.companyType}</p>
                  <p className="work_info">{experience.categoryType}</p>
                  <p className="work_date">{experience.date}</p>
                </div>
              </ExperienceCard>
            )
          })
        }
      </div>
    </ExperienceListCont>
  );
}

export default ExperienceList;
