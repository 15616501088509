import React from "react";
import styled from "styled-components";
import { MOBILE_WIDTH } from "../../constants";

const ContactButtonCont = styled.button`
  width: 18.7rem;
  height: 6.2rem;
  background: linear-gradient(95.08deg, #D6E2FF 13.48%, #81A4FF 83.07%);
  border-radius: 50%;
  font-size: 2.2rem;
  font-weight: var(--fw_bold);
  font-family: 'pretendard', var(--ff_eng), var(--ff_kor), "Malgun Gothic", "맑은 고딕", sans-serif;
  color: var(--black1);
  letter-spacing: -0.02em;

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    width: 137px;
    height: 43px;
    font-size: 16px;
  }
`;

const ContactUsButton = ({
  className,
  onClickFunc,
  children = '프로젝트 문의',
  animation,
  delay
}) => {

  return (
    <ContactButtonCont
      type="button"
      onClick={onClickFunc}
      className={className}
      data-aos={animation}
      data-aos-delay={delay}
    >
      {children}
    </ContactButtonCont>
  );
}

export default ContactUsButton;
