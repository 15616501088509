import React, { forwardRef } from "react";
import styled from "styled-components";
import CompanyInfo from "./CompanyInfo";
import {COMPANY_LOGO, MOBILE_WIDTH, TABLET_WIDTH} from "../constants";
import Button from "./button/Button";
import {NavLink} from "react-router-dom";

const FooterCont = styled.footer`
  padding: 61px 4.7% 68px;
  border-top: 1px solid var(--gray1);
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--black1);

  .inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .left_box {
      flex: 1 1 auto;
      display: flex;

      .img_logo {
        flex: 0 0 auto;
        width: 23.8rem;
        height: 38px;

        img {
          height: 100%;
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        margin-left: 10.5rem;

        .address {
          margin: 0;
          ul {
            li {
              p:not(.office) {
                font-weight: var(--fw_thin);
              }
            }
          }
        }

        > p {
          margin-top: 1.6rem;
          font-size: 1.6rem;
          font-weight: var(--fw_thin);
        }
      }
    }

    .right_box {
      flex: 0 0 auto;
      margin-left: 25px;
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    .inner {
      .left_box {
        .info {
          margin-left: 35px;
        }
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    padding: 30px 24px;

    .inner {
      .left_box {
        .img_logo {
          display: none;
        }

        .info {
          margin: 0;

          > p {
            margin-top: 72px;
            font-size: 11px;
          }
        }
      }

      .right_box {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`;

const FooterCompanyInfo = styled(CompanyInfo)`
  flex: 1 1 auto;
  margin-left: 25px;

  ul {
    display: flex;
    justify-content: space-between;

    li {
      flex: 0 1 auto;

      &:nth-of-type(n+2) {
        margin-left: 7rem;
      }

      p {
        font-size: 1.6rem;

        &:nth-child(n+2) {
          margin-top: 1.6rem;
        }
      }
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    ul {
      li {
        &:nth-child(n+2) {
          margin-left: 25px;
        }

        p {
          &:nth-child(n+2) {
            margin-top: 14px;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    ul {
      flex-direction: column;

      li {
        &:nth-child(n+2) {
          margin: 26px 0 0;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
`;

const StyledButton = styled(Button)`
  width: 8.8rem;
  border: none;

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    width: 36px;
  }
`;

const Footer = forwardRef(({
  className
}, ref) => {
  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <FooterCont
      className={className}
      ref={ref}
    >
      <div className="inner">
        <div className="left_box">
          <NavLink to="/" className="img_logo">
            <img src={COMPANY_LOGO} alt="흰색 이너뷰 로고"/>
          </NavLink>
          <div className="info">
            <FooterCompanyInfo className="address"/>
            <p>
              © COPYRIGHT {new Date().getFullYear()} BY INNERVIEW. ALL RIGHT RESERVED
            </p>
          </div>
        </div>
        <div className="right_box">
          <StyledButton onClick={handleScrollTop}>
            <img src="/img/footer_top.svg" alt="위쪽을 기리키는 화살표 아이콘" />
          </StyledButton>
        </div>
      </div>
    </FooterCont>
  );
});

export default Footer;
