import React, { useEffect, useState,useRef } from "react";
import styled from "styled-components";
import { GetData, PostData } from "../apis";
import ContentTitle from "../components/ContentTitle";
import InputBox from "../components/form/InputBox";
import TextBox from "../components/form/TextBox";
import PrivacyCheckWrap from "../components/PrivacyCheckWrap";
import WhiteButton from "../components/button/WhiteButton";
import { CompanyInfoObj, MOBILE_WIDTH, TABLET_WIDTH } from "../constants";
import InputFile from "../components/form/InputFile";
import { useMediaQuery } from "react-responsive";
import { makePhoneFormatExtension } from "../utils";
import { loadCaptchaEnginge, validateCaptcha } from "react-simple-captcha";
import AlertModal from "../components/AlertModal";
import { unstable_usePrompt } from "react-router-dom";
import PrivacyInfoModal from "../components/PrivacyInfoModal";
import Captcha from "../components/Captcha";

const WRAPPER_MARGIN        = '11.4%';
const WRAPPER_MARGIN_TABLET = '7%';

const RequestCont = styled.section`
  display: flex;
  align-items: flex-start;
  padding: 0 4.2%;

  .request_title {
    position: sticky;
    top: var(--header);
    left: 0;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - var(--header));
    padding: 4.2% 0;

    .subtext {
      margin-top: 4.8rem;
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 1.7;
    }

    .contact_info {
      .address {
        margin-bottom: 2rem;

        span {
          font-size: 1.8rem;
          font-weight: var(--fw_medium);
        }

        p {
          margin-top: 1.2rem;
          font-size: 2.4rem;
          font-weight: var(--fw_sbold);

          a {
            display: inline-flex;
            align-items: center;
            margin-left: 2rem;
            padding: 1.4rem 2.8rem 1.3rem 3.2rem;
            font-size: 1.5rem;
            font-weight: var(--fw_medium);
            border: 1px solid var(--gray1);
            border-radius: 50%;
            transition: var(--trans_time);
            
            img {
              width: 2.4rem;
              margin-left: 0.8rem;
              transition: var(--trans_time);
            }

            &:hover {
              color: var(--black1);
              background: var(--main1);

              img {
                filter: invert(0.85);
              }
            }
          }
        }
      }

      .call {
        display: flex;
        align-items: center;

        p {
          margin-right: 3.2rem;
          font-size: 2rem;
          font-weight: var(--fw_medium);

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .request_container {
    position: relative;
    flex: 1 1 100%;
    height: 100%;
    margin-top: 4.2%;
    padding: 0 0 8.8% 13.3%;
  }
  
  .require_text {
    position: absolute;
    top: 2px;
    right: 0;
    font-size: 1.8rem;
    font-weight: var(--fw_medium);
    line-height: 1.7;
  }

  .request_wrapper {
    margin-bottom: ${ WRAPPER_MARGIN };

    h3 {
      margin-bottom: 4rem;
      font-size: 2.4rem;
      font-weight: var(--fw_thin);
      line-height: 1.7;

      &.smargin {
        margin-bottom: 0.4rem;
      }

      br {
        display: none;
      }

      span {
        display: block;
        margin-top: 0.4rem;
        font-size: 1.6rem;
        color: var(--gray5);
      }
    }

    &.introduce_wrapper {
      .introduce_input_list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        > * {
          flex: 0 1 auto;
          width: calc(50% - 1rem);
          margin-bottom: 4rem;

          &:nth-child(2n) {
            margin-left: 2rem;
          }

          &:last-child {
            margin-bottom: 0;
          }

          h4 {
            margin-bottom: 0.8rem;
            font-size: 2rem;
            font-weight: var(--fw_medium);
            line-height: 1.7;
          }

          input {
            width: 100%;
          }
        }

        .input_wrap_full {
          width: 100%;
        }
      }
    }

    .check_input_list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      position: relative;
      width: calc(100% + 2rem);
      transform: translateX(-1rem);

      > * {
        flex: 0 1 auto;
        width: calc(33.33% - 2rem);
        margin: 0 1rem 1.6rem;

        &:nth-last-child(-n+3) {
          margin-bottom: 0;
        }
      }
    }
  }

  .privacy_checkbox_input {
    label {
      padding-left: 12px;
    }
  }

  .captcha_wrapper {
    margin-top: ${ WRAPPER_MARGIN };
  }

  .send_button_wrap {
    margin-top: ${ WRAPPER_MARGIN };
    text-align: center;
  }

  @media screen and (max-width: 1600px) {
    .request_container {
      padding-left: 8%;
    }
  }

  @media screen and (max-width: 1366px) {
    padding: 0 3.4%;

    .request_container {
      padding-left: 7.4%;
    }
  }

  @media screen and (max-width: 1280px) {
    padding: 0 3%;

    .request_container {
      padding-left: 6%;
    }

    .request_wrapper {
      &.introduce_wrapper {
        .introduce_input_list {
          & > * {
            &:nth-child(2n) {
              margin-left: 1.2rem;
            }
          }
        }
      }

      .check_input_list {
        & > * {
          margin: 0 0.6rem 1rem;
        }
      }
    }
  }

  @media screen and (max-width: ${ TABLET_WIDTH }px) {
    display: block;

    .request_title {
      position: static;
      display: block;
      height: auto;
      padding: 30px 0 40px;
      border-bottom: 1px dashed var(--gray2);

      .subtext {
        margin-top: 40px;
      }

      .contact_info {
        margin-top: 43px;

        .address {
          margin-bottom: 0;

          p {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            a {
              margin: 15px 0 0;
              border-color: var(--main1);
            }
          }
        }

        .call {
          display: none;
        }
      }
    }

    .request_container {
      padding-left: 0;
    }

    .require_text {
      display: none;
    }

    .request_wrapper {
      margin-bottom: ${ WRAPPER_MARGIN_TABLET };

      h3 {
        br {
          display: block;
        }
      }

      .check_input_list {
        > * {
          width: calc(33.3% - 1.4rem);
        }
      }
    }

    .captcha_wrapper {
      margin-top: ${ WRAPPER_MARGIN_TABLET };
    }

    .send_button_wrap {
      margin-top: ${ WRAPPER_MARGIN_TABLET };
    }
  }

  @media screen and (max-width: ${ MOBILE_WIDTH }px) {
    padding: 0 24px 207px 24px;

    .request_title {
      margin-bottom: 40px;

      .subtext {
        font-size: 14px;
      }

      .contact_info {
        .address {
          span {
            font-size: 16px;
            font-weight: var(--fw_base);
          }

          p {
            margin-top: 15px;
            font-size: 18px;

            a {
              padding: 12px 18px 12px 24px;

              &:hover {
                background: transparent;
                color: var(--white1);

                img {
                  filter: none;
                }
              }

              img {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }

    .request_container {
      padding-bottom: 0;
    }

    .request_wrapper {
      margin-bottom: 60px;

      h3 {
        margin-bottom: 32px;
        font-size: 14px;

        span {
          margin-top: 0;
          margin-bottom: -16px;
          font-size: 12px;
          font-weight: var(--fw_medium);
        }
      }

      .check_input_list {
        width: calc(100% + 16px);
        transform: translateX(-8px);

        > * {
          flex: 0 1 auto;
          width: calc(50% - 8px);
          margin: 0 4px 8px;

          &:nth-last-child(-n+4) {
            margin-bottom: 8px;
          }

          &:nth-last-child(-n+2) {
            margin-bottom: 0;
          }
        }
      }

      &.introduce_wrapper {
        .introduce_input_list {
          display: block;

          > * {
            width: 100%;
            margin-bottom: 32px;

            &:nth-child(2n) {
              margin-left: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }

            h4 {
              margin-bottom: 6px;
              font-size: 14px;
            }
          }
        }
      }
    }

    .captcha_wrapper {
      margin-top: 60px;
    }

    .send_button_wrap {
      margin-top: 80px;
    }
  }
`;

const StyledCheckRadioBox = styled(InputBox)`
  input {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    min-width: initial !important;
    min-height: initial !important;
    overflow: hidden !important;
    opacity: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;

    & + label {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 6.8rem;
      padding: 0;
      font-size: 1.8rem;
      font-weight: var(--fw_medium);
      color: var(--gray2);
      letter-spacing: -0.01em;
      text-align: center;
      border: 1px solid var(--gray1);
      transition: background 0.1s;

      &:hover {
        background: #393939;
        border-color: #393939;
      }

      &[isImg] {
        display: flex;
        flex-direction: column;
        height: 16rem;

        img {
          width: 4.8rem;
          margin-bottom: 1.2rem;
        }
      }
    }

    &:checked + label {
      border-color: var(--white1);
      font-weight: var(--fw_medium);
      color: var(--white1);

      &[isImg] img {
        filter: invert(0%) sepia(20%) saturate(439%) hue-rotate(193deg) brightness(113%) contrast(104%);
      }
    }
  }

  @media screen and (max-width: ${ MOBILE_WIDTH }px) {
    input {
      &[type="radio"] + label {
        font-size: 13px;
      }

      & + label {
        height: 44px;
        font-size: 14px;
        font-weight: var(--fw_medium);

        &:hover {
          background: transparent;
        }

        &[isImg] {
          display: flex;
          flex-direction: column;
          height: 104px;
  
          img {
            width: 28px;
            margin-bottom: 8px;
          }
        }
      }

      &:not(:checked) + label:hover {
        border-color: var(--gray1);
      }
    }
  }
`;

const Request = ({ gtag }) => {
    // Captcha
    const [ captcha, setCaptcha ] = useState('');
    const handleCaptcha           = (e) => {
        setCaptcha(e.target.value);
    }

    // gtm
    function gtag_report_conversion(url) {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-11303882783/7DcmCKXcz9MYEJ-gjo4q', 'event_callback': callback
        });
        return false;
    }

    const [ privacyModalShow, setPrivacyModalShow ] = useState(false);
    const [ alertModalShow, setAlertModalShow ]     = useState(false);
    const closeModal                                = () => {
        if (privacyModalShow) setPrivacyModalShow(false);
        if (alertModalShow) setAlertModalShow(false);
    }

    const selectedBudget = [
        '5백만 원 미만',
        '5백~1천만 원',
        '1천~3천만 원',
        '3천~5천만 원',
        '5천만 원 이상',
        '미정'
    ];

    const serviceCategory = GetData("getCategory", { query: { pageName: "contact" } });

    const { trigger } = PostData('postRequestSend', {
        onSuccess : () => {
            console.log("success")
            setAlertModalShow(true);
            setRequestData(requestDataInit);
            setCaptcha('');
            setInitFIle(true);
            validateCaptcha('');
            setIsSomethingChanged(false);
        }, onError: () => {
            console.log("error")
        }
    });
    const requestDataInit = {
        companyName: '',
        // companyType: '',
        name: '',
        phone: '',
        email: '',
        url: '',
        // projectType: '',
        projectBudget: '',
        detail: '',
        agree: 0,
        CategoryId: [],
        file: null,
    }

    const [ requestData, setRequestData ]               = useState(requestDataInit);
    const [ requestDataValid, setRequestDataValid ]     = useState(false);
    const [ isSomethingChanged, setIsSomethingChanged ] = useState(false);
    const [ initFile, setInitFIle ]                     = useState(false);

    const handleInputData = (key, value) => {
        if (key === "phone") {
            value = makePhoneFormatExtension(value)
        }
        requestData[key] = value
        setRequestData({ ...requestData })
        if (value) {
            setIsSomethingChanged(true);
        }
    }

    useEffect(() => {
        handleApplyData()
    }, [ requestData, captcha ]);

    const handleApplyData = () => {
        if (requestData.companyName !== '' &&
            requestData.name !== '' &&
            requestData.phone !== '' &&
            requestData.email !== '' &&
            requestData.agree &&
            requestData.CategoryId.length &&
            captcha !== '') {
            setRequestDataValid(true);
        } else {
            setRequestDataValid(false);
        }
    }

    const companyNameInput = useRef(null);
    const nameInput        = useRef(null);
    const phoneInput       = useRef(null);
    const emailInput       = useRef(null);
    const categoryInput    = useRef(null);

    const handleSendData = () => {
        if (requestData.companyName === '') {
            alert('회사/기업명을 입력해 주세요.');
            companyNameInput.current.focus();
            return false;
        }

        if (requestData.name === '') {
            alert('담당자 성함을 입력해 주세요.');
            nameInput.current.focus();
            return false;
        }

        if (requestData.phone === '') {
            alert('연락처를 입력해 주세요.');
            phoneInput.current.focus();
            return false;
        }

        if (requestData.email === '') {
            alert('이메일을 입력해 주세요.');
            emailInput.current.focus();
            return false;
        }

        if (!requestData.CategoryId.length) {
            alert('프로젝트 분야를 선택해 주세요.');
            categoryInput.current.focus();
            return false;
        }

        const regexPhone = /(010|0[1-9]+|070|050[1-9])-\d{3,4}-\d{4}$/;
        if (!regexPhone.test(requestData.phone)) {
            alert('올바른 형식의 전화번호를 입력해 주세요.');
            phoneInput.current.focus();
            return false;
        }

        const regexEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,}$/;
        if (requestData.email && !regexEmail.test(requestData.email)) {
            alert('올바른 형식의 이메일 주소를 입력해 주세요.');
            emailInput.current.focus();
            return false;
        }

        if (!requestData.agree) {
            alert('개인정보처리방침에 동의해 주세요.');
            return false;
        }

        if (!validateCaptcha(captcha)) {
            alert('자동입력방지 문자가 일치하지 않습니다. 다시 입력해 주세요.');
            loadCaptchaEnginge(4);
            setCaptcha('');
            return false;
        }

        const formData = new FormData();
        for (let key in requestData) {
            formData.append(key, requestData[key]);
        }

        const arg = {
            data: formData, config: {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        }

        if (requestDataValid)  {
        trigger(arg);

        // gtm
        gtag_report_conversion();
        }

    }

    unstable_usePrompt({
        when: isSomethingChanged, message: "작성중인 내용이 있습니다. 페이지를 벗어나시겠습니까?\n작성된 내용은 저장되지 않습니다.",
    });

    const isMobile = useMediaQuery({ query: `(max-width: ${ MOBILE_WIDTH }px)` });

    return (<>
            <RequestCont>
                <div className="request_title">
                    <div>
                        <ContentTitle
                            subtitle="Project contact"
                        >
                            <b>프로젝트</b> 문의
                        </ContentTitle>
                        <p className="subtext">
                            의뢰서를 제출해 주시면<br/>
                            <b>담당자가 3일 이내에 회신</b> 드릴 예정입니다.
                        </p>
                    </div>
                    <div className="contact_info">
                        <div className="address">
                            <span>오시는 길</span>
                            <p>
                                { CompanyInfoObj.address }
                                <a href="https://naver.me/FM6QOO5d" target="_blank">
                                    지도 보기
                                    <img src="/img/arr_upright.svg" alt="오른쪽 위를 가리키는 화살표"/>
                                </a>
                            </p>
                        </div>
                        <div className="call">
                            <p>Tel. { CompanyInfoObj.tel }</p>
                            <p>Email. { CompanyInfoObj.email }</p>
                        </div>
                    </div>
                </div>

                <div className="request_container">
                    <p className="require_text">
                        <i className="require">*</i>&nbsp;필수 작성 항목
                    </p>
                    <div className="request_wrapper introduce_wrapper">
                        <h3>
                            안녕하세요, <br/><b>의뢰하시는 분의 정보</b>를 알려주세요.
                        </h3>
                        <div className="introduce_input_list">
                            <div className="input_wrap">
                                <h4>회사/기업명&nbsp;<i className="require">*</i></h4>
                                <InputBox
                                    value={ requestData.companyName }
                                    ref={companyNameInput}
                                    onChange={ (e) => handleInputData("companyName", e.target.value) }
                                />
                            </div>
                            <div className="input_wrap">
                                <h4>담당자 성함&nbsp;<i className="require">*</i></h4>
                                <InputBox
                                    value={ requestData.name }
                                    ref={nameInput}
                                    onChange={ (e) => handleInputData("name", e.target.value) }
                                />
                            </div>
                            <div className="input_wrap">
                                <h4>연락처&nbsp;<i className="require">*</i></h4>
                                <InputBox
                                    placeholder="‘-’을 제외하고 숫자만 입력해주세요."
                                    value={ requestData.phone }
                                    ref={phoneInput}
                                    onChange={ (e) => {
                                        if (isNaN(Number(e.nativeEvent.data)) || e.nativeEvent.data === ' ') return;
                                        handleInputData("phone", e.target.value)
                                    } }
                                />
                            </div>
                            <div className="input_wrap">
                                <h4>이메일&nbsp;<i className="require">*</i></h4>
                                <InputBox
                                    value={ requestData.email }
                                    ref={emailInput}
                                    onChange={ (e) => handleInputData("email", e.target.value) }
                                />
                            </div>
                            <div className="input_wrap input_wrap_full">
                                <h4>현재 웹사이트</h4>
                                <InputBox
                                    placeholder="현재 운영 중인 사이트가 있다면 URL을 입력해 주세요."
                                    value={ requestData.url }
                                    onChange={ (e) => handleInputData("url", e.target.value) }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="request_wrapper">
                        <h3>
                            의뢰하실 <b>프로젝트 분야</b>를 알려주세요.&nbsp;<i className="require">*</i>
                            <span>* 최소 1가지 이상을 선택해 주세요. 중복 선택도 가능합니다.</span>
                        </h3>
                        <div className="check_input_list">
                            { serviceCategory.isLoading ? <></> : serviceCategory.data.map((item, index) => (
                                <StyledCheckRadioBox
                                    key={ index }
                                    type="checkbox"
                                    id={ 'service_' + item.type }
                                    name={ 'service_' + item.type }
                                    labelText={ item.type }
                                    src={ item.icon }
                                    alt={ item.type + " 아이콘" }
                                    value={ item.type }
                                    readOnly={ true }
                                    ref={categoryInput}
                                    checked={ requestData.CategoryId.includes(item.id) }
                                    onClick={ () => {
                                        const value = [ ...requestData.CategoryId ]
                                        if (value.includes(item.id)) {
                                            const index = requestData.CategoryId.indexOf(item.id)
                                            value.splice(index, 1)
                                        } else {
                                            value.push(item.id)
                                        }
                                        handleInputData("CategoryId", value)
                                    } }
                                />)) }
                        </div>
                    </div>

                    <div className="request_wrapper">
                        <h3>
                            <b>프로젝트 내용</b>에 대해 더 자세히 알려주세요.
                            <span>* 최대 5,000자까지 입력 가능합니다.</span>
                        </h3>
                        <div>
                            <TextBox
                                width="100%"
                                height={ isMobile ? '310px' : '300px' }
                                placeholder="프로젝트 목적, 레퍼런스, 기존 웹사이트, 브랜드 방향성 등 프로젝트에 대해 자세하게 말씀해 주세요."
                                value={ requestData.detail }
                                onChange={ (e) => handleInputData("detail", e.target.value) }
                                currentLength={ requestData.detail.length }
                                maxLength={ 5000 }
                            ></TextBox>
                        </div>
                    </div>

                    <div className="request_wrapper">
                        <h3 className="smargin">
                            <b>추가 자료</b>가 있다면 첨부해 주세요.
                        </h3>
                        <InputFile
                            sizeLimit={ 100 }
                            onChange={ (files) => {
                                handleInputData("file", files ? files[0] : files);
                            } }
                            initFile={ initFile }
                            setInitFile={ setInitFIle }
                        />
                    </div>

                    <div className="request_wrapper">
                        <h3 style={{marginBottom: 20}}>
                            정해두신 <b>프로젝트의 예산</b>을 선택해 주세요.
                        </h3>
                        <div className="check_input_list">
                            { selectedBudget.isLoading ? <></> : selectedBudget.map((item, index) => (
                                <StyledCheckRadioBox
                                    key={ index }
                                    type="radio"
                                    id={ 'budget_' + item }
                                    name="budget_radio"
                                    labelText={ item }
                                    value={ item }
                                    readOnly={ true }
                                    checked={ requestData.projectBudget === item }
                                    onClick={ () => handleInputData("projectBudget", item) }
                                />)) }
                        </div>
                    </div>

                    <PrivacyCheckWrap
                        checked={ requestData.agree }
                        onClickCheck={ (e) => handleInputData("agree", e.target.checked) }
                        onClickMore={ () => setPrivacyModalShow(!privacyModalShow) }
                    />
                    <Captcha
                        className="captcha_wrapper"
                        captcha={ captcha }
                        handleCaptcha={ handleCaptcha }
                    />

                    <div className="send_button_wrap">
                        <WhiteButton
                            type="button"
                            className="apply_send_button on"
                            onClick={ handleSendData }
                        >
                            Send
                        </WhiteButton>
                    </div>

                </div>
            </RequestCont>
            { privacyModalShow ? (<PrivacyInfoModal
                    closeModal={ closeModal }
                ></PrivacyInfoModal>) : (<></>) }
            { alertModalShow ? (<AlertModal
                    closeModal={ closeModal }
                >
                    <p>이너뷰에 의뢰해 주셔서 감사합니다.</p>
                    <p>담당자 검토 후 빠른 시일 내로<br/>회신 드리겠습니다.</p>
                </AlertModal>) : (<></>) }
        </>);
}

export default Request;