import axios from "axios";
import {API_ROUTES, HOST_NAME} from "../constants";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import useSWRMutation from 'swr/mutation'
const getFetcher = (url) => axios.get(url).then((res) => res.data);
const postFetcher = (url, { arg }) => axios.post(url, arg.data, arg.config).then((res) => res.data);
const makeQuery = (query) => {
    return Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join("&");
}
export const GetData = (route, option) => {
    let url = `${HOST_NAME}${API_ROUTES[route]}`;
    if (option) {
        let { params, query } = option;
        if (params) url += '/' + params;
        if (query) url += '?' + makeQuery(query);
    }

    const { data, error, isLoading } = useSWR(url, getFetcher);

    return {
        data: data,
        isLoading: isLoading,
        isError: error
    };
}

export const GetExperienceListInfinity = (route, option) => {
    let url = `${HOST_NAME}${API_ROUTES[route]}`;
    if (option) {
        let {params, query} = option;
        if (params) url += '/' + params;
        if (query) url += '?' + makeQuery(query); else url += '?';
    }

    const getKey = (pageIndex, previousPageData) => {
        if (previousPageData && previousPageData.length < 8) return null
        return `${url}&page=${pageIndex}&limit=8`
    }

    const {data, error, isLoading, isValidating, mutate, size, setSize} = useSWRInfinite(getKey, getFetcher);

    return {
        data: data,
        isLoading: isLoading,
        isError: error,
        size: size,
        setSize: setSize,
    };
}
export const PostData = (route, option) => {
    let url = `${HOST_NAME}${API_ROUTES[route]}`;

    const { data, error, trigger, isMutating, reset } = useSWRMutation(url, postFetcher, option);

    return {
        data: data,
        trigger: trigger,
        isError: error
    };
}