import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import KakaoMap from "../components/KakaoMap";
import AOS from "aos";
import 'aos/dist/aos.css';
import ContactUsButton from "../components/button/ContactUsButton";
import { CompanyInfoObj, MOBILE_WIDTH, TABLET_WIDTH } from "../constants";

const ContactCont = styled.section`
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - var(--header) - var(--footer));

  [data-aos=fade-up] {
    transform: translate3d(0,60px,0);
  }

  .contact_info {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: 4.2% 3.4% 3.1%;

    .contact_info_text {
      flex: 0 1 auto;

      * {
        font-family: var(--ff_kor);
      }

      h2 {
        margin-bottom: 35px;
        font-size: 5.4rem;
        font-weight: var(--fw_bold);
        line-height: 1.3;
        word-break: keep-all;
      }
  
      div {
        margin-bottom: 46px;
      }
  
      p {
        font-size: 2.2rem;
        font-weight: var(--fw_thin);
        line-height: 1.6;
  
        &:nth-of-type(n+2) {
          margin-top: 5px;
        }
      }
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    .contact_info {

      .contact_info_text {
        h2 {
          font-size: 4.8rem
        }

        p {
          font-size: 2rem;
        }
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    display: block;
    min-height: auto;
    padding-bottom: 44px;

    .contact_info {
      padding: 38px 24px 0;
      align-items: center;

      .contact_info_text {
        h2 {
          margin-bottom: 28px;
          font-size: 30px;
        }

        div {
          margin-bottom: 32px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
`;

const StyledKakaoMap = styled(KakaoMap)`
  flex: 0 0 auto;
  width: 57.1%;
  height: auto;
  padding-bottom: 41.7%;

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    width: 100%;
    padding-bottom: 69.4%;
    margin-top: 60px;
  }
`;

const StyledContactUsButton = styled(ContactUsButton)`
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    margin-top: 88px;
  }
`;


const Contact = () => {
  const navigate = useNavigate();

  const AOS_OPTIONS = {
    animation: "fade-up",
    delay: "600",
  }
  
  useEffect(() => {
    AOS.init({
      disable: () => {
        return window.innerWidth <= MOBILE_WIDTH;
      },
      duration: 1000,
      offset: 100,
      delay: 100,
    });
  },[]);  

  const handleButtonClick = () => {
    navigate("/request");
  };

  return (
    <ContactCont>
      <StyledKakaoMap/>
      <div className="contact_info">
        <div className="contact_info_text">
          <h2 data-aos="fade-up">{CompanyInfoObj.address}</h2>
          <div>
            <p data-aos="fade-up" data-aos-delay="200">Tel. {CompanyInfoObj.tel}</p>
            <p data-aos="fade-up" data-aos-delay="300">Email. {CompanyInfoObj.email}</p>
          </div>
          <div>
            <p data-aos="fade-up" data-aos-delay="400"><b>오시는 길</b></p>
            <p data-aos="fade-up" data-aos-delay="500">{CompanyInfoObj.howto}</p>
          </div>
        </div>
        <StyledContactUsButton onClickFunc={handleButtonClick} {...AOS_OPTIONS} />
      </div>
    </ContactCont>
  );
}

export default Contact;
