import React from "react";
import styled from "styled-components";
import { MOBILE_WIDTH } from "../constants";
import Modal from "./Modal";

const StyledModal = styled(Modal)`
  .modal_background {
    .modal_body {
      width: 560px;
      height: 530px;
      background-color: var(--black1);
      box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.25);
      padding: 2.4rem;
  
      .modal_cont {
        position: relative;
        height: 100%;
  
        .modal_title {
          h3 {
            text-align: center;
            font-size: 2.2rem;
            font-weight: var(--fw_500);
            line-height: 1.7;
            padding-top: 1.6rem;
          }
        }
  
        .modal_text {
          border: 1px solid var(--gray1);
          padding: 1.6rem;
          margin-top: 16px;
          padding: 16px 8px 16px 16px;
          height: calc(100% - 16px - 2.2rem*1.7 - 1.6rem);
  
          .text_inner {
            height: 100%;
            overflow-y: auto;
            scrollbar-width: thin;
            scrollbar-color: var(--white1) var(--gray1);
  
            &::-webkit-scrollbar {
              width: 4px;
            }
  
            ::-webkit-scrollbar-thumb {
              height: 70%;
              background: var(--white1);
              border-radius: 4px;
            }
  
            p {
              font-size: 13px;
              font-weight: var(--fw_500);
              line-height: 1.7;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
  
  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    .modal_background {
      .modal_body {
        width: 100%;
        height: 100vh;
        padding: 0;
        box-shadow: none;
  
        .modal_cont {
          .modal_title {
            padding: 23px 24px; 
            border-bottom: 1px solid var(--gray1);
            display: flex;
            align-items: center;
            justify-content: space-between;
  
            h3 {
              text-align: left;
              font-size: 15px;
              font-weight: var(--fw_700);
              line-height: var(--lh_min);
              padding-top: 0;
            }
            
            .close_btn {
              position: relative;
            }
          }
  
          .modal_text {
            margin-top: 0;
            border: 0 none;
            padding: 24px;
            height: calc(100% - ( 15px*1.7 + 17px*2 + 1px ));
  
            .text_inner {
              scrollbar-width: none;
  
              &::-webkit-scrollbar {
                display: none;
              }
  
              p {
                margin-right: 0;
                font-size: 14px;
              }
            }
          }
        }

        .close_btn {
          right: 24px;
        }
      }
    }
  }
`;


const PrivacyInfoModal = ({
  className,
  closeModal
}) => {
  
  return (
    <StyledModal
      className={className}
      closeModal={closeModal}
    >
      <div className="modal_cont">
        <div className="modal_title">
          <h3>개인정보처리방침</h3>
        </div>
        <div className="modal_text">
          <div className="text_inner">
            <p>
              이너뷰는 정보통신망 이용촉진 및 정보보호등에 관한법률 (이하 “정보통신망법”) 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. <br/><br/>
              1. 개인정보의 수집·이용 목적<br/>
              - 채용 : 입사지원자의 지원 내용을 채용 과정에 참고, 개인 식별, 본인 확인을 위해 수집합니다.<br/>
              - 프로젝트 상담 : 의뢰인의 프로젝트에 대한 내용, 개발 방법 등의 문의에 대한 정를 정확한 답변을 위해 수집됩니다.<br/><br/>
              2. 수집항목<br/>
              - 회사/기업명, 성명, 이메일, 휴대전화, 프로젝트명, 예산, 작업 기간, 첨부파일, 의뢰 내용<br/><br/>
              3. 보유이용기간<br/>
              원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.<br/><br/>
              동의를 거부할 권리 및 동의를 거부할 경우의 이익<br/>
              위 개인정보 중 필수적 정보의 수집 &middot; 이용에 관한 동의는 의뢰를 위하여 필수적이므로, 위 사항에 동의하셔야만 의뢰가 가능합니다.
            </p>
          </div>
        </div>
      </div>
    </StyledModal>
  );
}

export default PrivacyInfoModal;