import React from "react";
import styled from "styled-components";

const DefaultButton = styled.button`
  ${({backgroundColor, border}) => `
    ${border.width ? `border-width: ${border.width};` : 'border-width: 0;'};
    ${border.color ? `border-color: ${border.color}` : ''};
    ${border.radius ? `border-radius: ${border.radius}` : ''};
    border-style: solid;
    background: ${backgroundColor};
  `};

  ${({font}) => `
    ${font.color ? `color: ${font.color}` : ''};
    ${font.size ? `font-size: ${font.size}` : ''};
    ${font.weight ? `font-weight: ${font.weight}` : ''};
  `};
`;

const Button = React.memo(
  React.forwardRef(({
    type = 'button',
    className,
    border = {
      radius: '0',
      color: 'transparent',
    },
    backgroundColor,
    font = {},
    onClick = () => null,
    children,
    ...props
  }, ref) => {
  
    return (
      <DefaultButton
        ref={ref}
        className={className}
        border={border}
        backgroundColor={backgroundColor}
        font={font}
        type={type}
        onClick={onClick}
        {...props}
      >
        {children}
      </DefaultButton>
    );
  })
);

export default Button;
