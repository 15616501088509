import React, {useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import styled from "styled-components";
import Navbar from "./Navbar";
import {COMPANY_LOGO, MOBILE_WIDTH} from "../constants";
import Button from "./button/Button";

const HeaderCont = styled.header`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--header_index);
  width: 100%;
  max-width: 100vw;
  height: var(--header);
  display: flex;
  align-items: center;
  padding: 0 4.2%;
  background: rgba(39, 39, 39, 0.5);
  border-bottom: 1px solid var(--gray1);
  transition: 0.5s;
  transition-property: border, background;
  transition-delay: 0.5s;

  &.header_nav_on {
    background: transparent;
    border-bottom-color: transparent;
    transition: var(--trans_time);
    transition-delay: 0s;
  }

  .header-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .header-comment {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-weight: var(--fw_medium);
    text-transform: capitalize;

    span {
      font-size: 12px;
      text-transform: none;
    }
  }

  .menu-button-area {
    display: flex;
    align-items: center;

    p {
      margin-right: 5px;
      font-size: 14px;
      font-weight: var(--fw_medium);
      transition: var(--trans_time);
    }

    .menu-button {
      position: relative;
      width: 26px;
      height: 26px;

      span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 23px;
        height: 1px;
        background: var(--white1);
        border-radius: 4px;
        display: inline-block;
        transition: var(--trans_time);

        &:nth-of-type(1) {
          top: 5px;
        }

        &:nth-of-type(2) {
          width: 18px;
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
        }

        &:nth-of-type(3) {
          bottom: 4px;
        }
      }

      &.on {
        span {
          &:nth-of-type(1) {
            top: 50%;
            transform: translate(-50%, -50%) rotate(-45deg);
          }

          &:nth-of-type(2) {
            opacity: 0;
          }

          &:nth-of-type(3) {
            top: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px){
    padding: 0 24px;

    &.bg_black:not(.header_nav_on) {
      background: var(--black1);
    }

    .nav-logo {
      width: 105px;
    }
    
    .header-comment {
      display: none;
    }

    .menu-button-area {
      p {
        display: none;
      }
    }
  }
`;

function Header({className}) {
  let location = useLocation().pathname.split("/")[1];
  if(useLocation().pathname.indexOf('experience/detail') > -1) location = 'portfolio';
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <>
      <HeaderCont
        className={`${className} ${click ? 'header_nav_on' : ''}`}
      >
        <div className="header-container">
          <NavLink
            to="/"
            className="nav-logo"
            onClick={() => setClick(false)}
          >
            <img src={COMPANY_LOGO} alt="흰색 이너뷰 로고"/>
          </NavLink>

          <p className="header-comment">
            {
              !click
              ? !!location
                ? location
                : <span>We look into your mind</span>
              : 'menu'
            }
          </p>

          <div className="menu-button-area">
            <p className={click ? 'displaynone' : ''}>
              MENU
            </p>
            <Button
              onClick={handleClick}
              className={`menu-button ${click ? 'on' : ''}`}
            >
              <span></span>
              <span></span>
              <span></span>
            </Button>
          </div>
        </div>
      </HeaderCont>
      {click ? (
        <Navbar className="on" location={location} onClick={setClick}/>
      ) : (
        <Navbar location={location} onClick={setClick}/>
      )}
    </>
  );
}

export default Header;
