import React from "react";
import styled from "styled-components";
import { MOBILE_WIDTH, TABLET_WIDTH } from "../constants";

const H2 = styled.h2`
  font-size: 5.6rem;
  font-weight: var(--fw_medium);
  line-height: 1.4;

  span {
    display: block;
    margin-bottom: 6px;
    font-size: 2.4rem;
    font-weight: var(--fw_base);
    color: var(--main1);
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    font-size: 4.8rem;

    span {
      font-size: 2.2rem;
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 32px;

    span {
      margin-bottom: 4px;
      font-size: 16px;
    }
  }
`;

const ContentTitle = ({
  children,
  subtitle,
  aos,
}) => {
  return (
    <H2 data-aos={aos}>
      <span>{subtitle}</span>
      {children}
    </H2>
  );
}

export default ContentTitle;
