import React, { useEffect } from "react";
import styled from "styled-components";
import { responsiveFontMixin } from "../styles/mixins";
import { MOBILE_WIDTH, TABLET_WIDTH, SCROLL_DURATION, SCROLL_DELAY, SCROLL_OFFSET } from "../constants";
import AOS from "aos";
import 'aos/dist/aos.css';
import { useMediaQuery } from "react-responsive";
import {GetData} from "../apis";

const AboutCont = styled.section`

  [data-aos=fade-up] {
    transform: translate3d(0,200px,0);
  }

  .about_content_container {
    &:nth-child(n+2) {
      margin-top: 11.5%;
    }
  }
  
  .orientation_container {
    display: flex;
    flex-wrap: wrap;
    padding: 6.1% 11% 0;

    h2 {
      flex: 0 0 auto;
      padding-right: 4%;
      margin-bottom: 30px;
      ${responsiveFontMixin(80, 32, 1920, 1024)};
      font-weight: var(--fw_thin);
      line-height: 1.4;
    }

    .orientation_wrapper {
      flex: 1 1 auto;

      .orientation_box {
        &:nth-child(n+2) {
          margin-top: 34px;
        }

        h3 {
          ${responsiveFontMixin(80, 32, 1920, 1024)};
          font-weight: var(--fw_bold);
          line-height: 1.4;
          margin-bottom: 10px;
        }

        p {
          ${responsiveFontMixin(24, 14, 1920, 1024)};
          font-weight: var(--fw_thin);
          line-height: 1.5;
          color: var(--main1);

          br {
            display: none;
          }
        }
      }

      .orientation_text {
        margin-top: 70px;
        ${responsiveFontMixin(18, 12, 1920, 1024)};
        color: #d6d6d6;
        line-height: 2.2;
        word-break: keep-all;

        br:first-child {
          display: none;
        }
      }
    }
  }

  .process_container {

    .wrapper {
      display: flex;
      padding-left: 10.7%;

      .badge {
        flex: 0 0 auto;
        position: relative;
        width: 22.7rem;
        height: 7.2rem;
        line-height: 7.2rem;
        margin-right: 86px;
        font-size: 1.8rem;
        color: var(--main1);
        text-align: center;
        border: 1px solid var(--gray4);
        border-radius: 50%;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: -12px;
          transform: translate(100%, -50%);
          display: inline-block;
          width: 62px;
          height: 1px;
          border-top: 1px dashed var(--gray4);
        }
      }

      .content_box {
        flex: 1 1 auto;
      }

      .main_title {
        padding-top: 20px;
        ${responsiveFontMixin(40, 22, 1920, 360)};
        line-height: 1.1;

        br {
          display: none;
        }
      }

      &.team_wrapper {
        .content {
          margin-top: 7.4%;
          text-align: right;
        }
      }

      &.business_wrapper {
        margin-top: 5.8%;

        .content {
          margin-top: 40px;

          .subtitle {
            ${responsiveFontMixin(20, 12, 1920, 360)};
            line-height: 2;
          }

          .business_list {
            margin-top: 80px;

            .business_item {
              padding: 28px 30px 0 0;
              border-top: 1px solid #6c6c6c;

              &:nth-child(2) {
                position: relative;

                &::before {
                  content :'';
                  background: url(/img/about_bg.png) no-repeat top right;
                  background-size: auto 100%;
                  mix-blend-mode: color-dodge;
                  width: 100%;
                  padding-top: 132%;
                  position: absolute;
                  top: 0; 
                  left: 0;
                  margin-top: -66%;
                  z-index: -1;
                }
              }

              &:nth-child(n+2) {
                margin-top: 80px;
              }

              .business_title {
                display: flex;
                align-items: center;
                
                h3 {
                  font-size: 3.6rem;
                  color: var(--main1);
                  margin-right: 3.2rem;
                }

                ul {
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;

                  li {
                    font-size: 2rem;
                    font-weight: var(--fw_medium);
                    color: var(--main1);
                    margin-right: 3rem;
                    line-height: var(--lh_base);

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }

              .info {
                margin-top: 39px;
                font-size: 1.8rem;
                line-height: 1.7;
              }

              .plan_list {
                margin-top: 24px;
                padding-left: 10px;
                
                li {
                  display: flex;
                  font-size: 1.6rem;
                  line-height: 1.6;
                  color: #b5b5b5;

                  &:nth-child(n+2) {
                    margin-top: 11px;
                  }

                  &::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    margin: 10px 11px 0 0;
                    background: #b5b5b5;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }
      }

      &.partners_wrapper {
        margin: 0 auto;
        padding-top: 9.8%;
        padding-bottom: 12.5%;

        .content {
          margin-top: 2.4rem;

          .subtitle {
            ${responsiveFontMixin(20, 12, 1920, 360)};
            font-weight: var(--fw_thin);
            line-height: 2;
          }

          .partners_list {
            margin: 8rem 8rem 0 0;
            display: flex;
            flex-wrap: wrap;

            .partner_item {
              width: calc( (100% - 2.8rem*3)/4 );
              height: 0;
              positon: relative;
              padding-bottom: calc( (100% - 2.8rem*3)/4 / 2.37);
              border: 1px solid var(--gray1); 
              margin-right: 2.8rem;
              margin-bottom: 2.8rem;

              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              &:nth-last-child(-n+4) {
                margin-bottom: 0;
              }

              &:nth-child(4n) {
                margin-right: 0;
              }
              
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1366px) {
    .about_content_container:nth-child(n+2) {
      margin-top: 13.2%;
    }

    .orientation_container {
      padding-left: 6%;
      padding-right: 6%;

      h2 {
        padding-right: 2.2%;
      }
    }

    .process_container {
      .wrapper {
        padding-left: 5%;

          .main_title {
            padding-top: 14px;
          }

          .content {
            margin-top: 30px;

            .business_list {
              margin-top: 60px;
            }
          }

        &.business_wrapper {
          margin-top: 6.8%;
        }

        &.partners_wrapper {
          padding-top: 10.8%;

          .content {
            margin-top: 30px;

            .partners_list {
              margin-top: 60px;

              .partner_item {
                margin-right: 21px;
                margin-bottom: 21px;
                width: calc( (100% - 21px*2)/3 );
                padding-bottom: calc( (100% - 21px*2)/3 / 2.37 );

                &:nth-child(n) {
                  margin-bottom: 21px;
                  margin-right: 21px;
                }

                &:nth-last-child(-n+3) {
                  margin-bottom: 0;
                }
  
                &:nth-child(3n) {
                  margin-right: 0;
                }
  

              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    .orientation_container {
      display: block;
      padding-top: 80px;
    }

    .process_container {
      .wrapper {
        display: block;
        padding-left: 28px;
        padding-right: 28px;

        .badge {
          width: 139px;
          height: 36px;
          line-height: 36px;
          margin: 0 auto;
          font-size: 12px;

          &::after {
            top: auto;
            right: auto;
            bottom: -30px;
            left: 50%;
            transform: translateX(-50%) rotate(90deg);
            width: 30px;
          }
        }

        .content_box {
          margin-top: 54px;
        }

        .main_title {
          padding-top: 0;
          text-align: center;
          line-height: 1.4;

          br {
            display: block;
          }
        }

        &.team_wrapper {
          .content {
            margin-top: 33px;

            img {
              width: 100%;
            }
          }
        }

        &.business_wrapper {  
          .content {
            margin-top: 16px;
            
            .subtitle {
              text-align: center;
            }

            .business_list {
              .business_item {
                padding-right: 0;

                &:nth-child(2)::before {
                  content :'';
                  background: url(/img/about_bg_mo.png) no-repeat top center;
                  background-size: 100% auto;
                  mix-blend-mode: color-dodge;
                  width: calc(100% + 56px);
                  padding-top: calc((100% + 56px) * 2.61);
                  position: absolute;
                  top: 0; 
                  left: -28px;
                  margin-top: calc(-1 * (100% + 56px) * 2.61/2);
                  z-index: -1;
                }  

                .business_title {
                  display: block;

                  h3 {
                    margin-bottom: 9px;
                  }
                }
              }
            }
          }
        }

        &.partners_wrapper {

          .content {
            .subtitle {
              text-align: center;
            }
            
            .partners_list {
              margin: 8rem 0 0 0;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {

    .about_content_container {
      &:nth-child(n+2) {
        margin-top: 100px;
      }
    }

    .process_container {
      .wrapper {
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    .orientation_container {
      padding: 80px 25px 0;

      h2 {
        margin-bottom: 16px;
        font-size: 20px;
      }

      .orientation_wrapper {
        .orientation_box {

          &:nth-child(n+2) {
            margin-top: 18px;
          }

          h3 {
            margin-bottom: 4px;
          }

          p {
            br {
              display: block;
            }
          }
        }

        .orientation_text {
          margin-top: 60px;

          br:first-child {
            display: block;
          }
        }
      }
    }

    .process_container {
      .wrapper {
        &.business_wrapper {
          margin-top: 64px;
          padding: 0 24px 0;

          &::before {
            bottom: -6%;
            background: url(/img/about_bg_mo.png) center /cover no-repeat;
          }    

          .content {
            .business_list {
              .business_item {
                padding-top: 24px;

                &:nth-child(2)::before {
                  width: calc(100% + 48px);
                  padding-top: calc((100% + 48px) * 2.61);
                  left: -24px;
                  margin-top: calc(-1 * (100% + 48px) * 2.61/2);
                }  

                &:nth-child(n+2) {
                  margin-top: 60px;
                }

                .business_title {

                  h3 {
                    font-size: 20px;
                  }

                  ul {
                    li {
                      font-size: 12px;
                      margin-right: 12px;
                    }
                  }
                }

                .info {
                  margin-top: 19px;
                  font-size: 12px;
                }

                .plan_list {
                  margin-top: 24px;

                  li {
                    font-size: 12px;

                    &:nth-child(n+2) {
                      margin-top: 12px;
                    }
                  }
                }
              }
            }
          }
        }

        &.partners_wrapper {
          margin: 0 auto;
          padding-top: 100px;
          padding-bottom: 60px;

          .content {
            margin-top: 16px;

            .partners_list {
              margin-top: 40px;

              .partner_item {
                margin-right: 14px;
                margin-bottom: 14px;
                width: calc( (100% - 14px)/2 );
                padding-bottom: calc( (100% - 14px)/2 / 2.37 );

                &:nth-child(n) {
                  margin-bottom: 14px;
                  margin-right: 14px;
                }

                &:nth-last-child(-n+2) {
                  margin-bottom: 0;
                }

                &:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
  
          }
        }
      }
    }
  }
`;

const About = () => {
  const isTablet = useMediaQuery({ query: `(max-width: ${TABLET_WIDTH}px)` });
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  useEffect(() => {
    AOS.init({
      duration: SCROLL_DURATION,
      offset: SCROLL_OFFSET,
      delay: SCROLL_DELAY,
    });
  },[]);

  const {data, isLoading, isError} = GetData('getBadge');

  return (
    <AboutCont>
      <div className="about_content_container orientation_container">
        <h2 data-aos="fade-up">INNERVIEW IS</h2>
        <div className="orientation_wrapper">
          <div className="orientation_box" data-aos="fade-up" data-aos-delay={SCROLL_DELAY*2}>
            <h3>PROFESSIONAL</h3>
            <p><b>전문가</b>로 구성된 그룹입니다.</p>
          </div>
          <div className="orientation_box" data-aos="fade-up" data-aos-delay={SCROLL_DELAY*3}>
            <h3>EXPLOSION</h3>
            <p><b>에너지</b>와 <b>크리에이티브한 생각</b>이 넘칩니다. </p>
          </div>
          <div className="orientation_box animation_anchor" data-aos="fade-up" data-aos-delay={SCROLL_DELAY*4}>
            <h3>DISTINCTION</h3>
            <p><b>명확한 방향 제시</b>를 통해&nbsp;<br/>고객이 원하는 <b>목표에 도달</b>할 수 있도록 함께합니다.</p>
          </div>
          <p className="orientation_text" data-aos="fade-up" data-aos-delay={SCROLL_DELAY*5} data-aos-anchor=".orientation_box.animation_anchor">
            이너뷰는 <b>모두를 위한 디지털 환경을 구축</b>합니다.<br/>
            2015년 창사 이래 9년간 자체적으로 구축한 다양한 솔루션을 통하여 고객사의 비즈니스와 사용자 경험을 개선하고 새로 창출해 왔습니다.
          </p>
        </div>
      </div>

      <div className="about_content_container process_container">
        <div className="wrapper team_wrapper">
          <div className="badge" data-aos="fade-up">Professional Team</div>
          <div className="content_box">
            <h2 className="main_title" data-aos="fade-up" data-aos-anchor=".team_wrapper .badge">
              이너뷰의 모든 프로젝트는&nbsp;<br/><b>전문가로 구성된 팀과 함께 합니다.</b>
            </h2>
            <div className="content" data-aos="fade-up">
              <img src={ isMobile ? '/img/about_process_mo.svg' : isTablet ? '/img/about_process_tab.svg' : '/img/about_process_pc.svg'} onLoad={()=> AOS.refresh()} alt="" />
            </div>
          </div>
        </div>
        <div className="wrapper business_wrapper">
          <div className="badge" data-aos="fade-up">Business Line</div>
          <div className="content_box">
            <h2 className="main_title" data-aos="fade-up" data-aos-anchor=".business_wrapper .badge">
              고객의 니즈 -&nbsp;<b>그 이상의 것을&nbsp;<br/>꿰뚫어 보려</b>&nbsp;노력합니다.
            </h2>
            <div className="content">
              <p className="subtitle" data-aos="fade-up" >
                서비스 구현에 있어서 가장 중요한 것은 문제를 정의하고 올바르게 해결하기 위한&nbsp;<b>‘최선의 방법’</b>을 찾아 나가는 것입니다.<br/>
                시장 . 서비스 .고객  - 이 세가지에 대한 리서치를 통해&nbsp;<b>‘진짜 문제’를 찾고 반복적인 질문을 통해 답을 찾아내는 과정</b>을 반복합니다.<br/>
                위의 과정을 통해 논리적인 분석에 창의적인 생각을 더해&nbsp;<b>고객에게 보다 ‘명확한 길’을 제시</b>합니다.
              </p>
              <div className="business_list">
                <div className="business_item" data-aos="fade-up">
                  <div className="business_title">
                    <h3>
                      SI Solution
                    </h3>
                    <ul>
                      <li>리서치</li>
                      <li>UIUX기획</li>
                      <li>GUI 디자인</li>
                      <li>WEB, APP, IoT system 개발</li>
                    </ul>
                  </div>
                  <p className="info">
                    이너뷰의 모든 SI 솔루션은 <b>사람에 대한 이해를 바탕</b>으로 합니다.<br/>
                    SI 솔루션의 모든 서비스는 클라이언트의 브랜드를 정확하게 반영하고 <b>문제 해결을 위한 전문적이고 사용자 친화적인 서비스를 만드는 것</b>을 목표로 합니다.
                  </p>
                  <ul className="plan_list">
                    <li>먼저 리서치와 데이터 분석을 통하여 산업과 비지니스의 전체적인 상황과 맥락을 이해하고 문제를 정의합니다.</li>
                    <li>정의된 문제를 해결하기 위한 명확한 방향성을 설정하고, 아이데이션을 통한 컨셉을 도출합니다.</li>
                    <li>컨셉을 반영한 프로토타입핑을 제작하여 실질적 문제해결이 가능한지 검증합니다.</li>
                    <li>지속적으로 기획자 - 디자이너 - 개발팀이 협업하여 서비스를 구현합니다.</li>
                    <li>QA를 통하여 원하는 수준의 품질까지 도달할 수  있도록 서비스를 체크&테스트합니다.</li>
                  </ul>
                </div>
                {/*<div className="business_item" data-aos="fade-up">*/}
                {/*  <div className="business_title">*/}
                {/*    <h3>*/}
                {/*      Marketing Solution*/}
                {/*    </h3>*/}
                {/*    <ul>*/}
                {/*      <li>데이터리포팅</li>*/}
                {/*      <li>온라인 IMC 기획, 제작</li>*/}
                {/*      <li>온라인 IMC 컨설팅</li>*/}
                {/*    </ul>*/}
                {/*  </div>*/}
                {/*  <p className="info">*/}
                {/*    이너뷰는 코로나로 인한 디지털 대전환을 맞이하며, 수많은 브랜드와의 온라인 마케팅 경험을 통하여 자체적인 솔루션을 구축해왔습니다.<br/>*/}
                {/*    통합 마케팅 커뮤니케이션&#40;IMC&#41;는 다양한 마케팅 채널을 결합하여 고객에게 일관되고 응집력 있는 브랜드의 메세지를 전달합니다.<br/>*/}
                {/*    데이터를 기반으로 웹사이트.검색 엔진.이메일.소셜 미디어 등을 통하여 다양한 방법으로 브랜드와 고객을 연결합니다.*/}
                {/*  </p>*/}
                {/*  <ul className="plan_list">*/}
                {/*    <li>브랜드가 원하는 데이터를 정확히 전달합니다.</li>*/}
                {/*    <li>데이터 기반의 다양한 형태의 온라인 IMC를 기획 및 제작합니다.</li>*/}
                {/*  </ul>*/}
                {/*</div>*/}
                <div className="business_item" data-aos="fade-up">
                  <div className="business_title">
                    <h3>
                      Business Design
                    </h3>
                    <ul>
                      <li>디지털 서비스 디자인</li>
                    </ul>
                  </div>
                  <p className="info">
                    이너뷰의 비지니스 디자인은 브랜드의 관점에서부터 시작합니다.<br/>
                    고객에게 전하고자 하는 브랜드의 가치를 컨셉화 하고, 명확한 산출물로 표현하는 것을 목표로 합니다.<br/>
                    기민한 전략과 낯선 창의력, 원숙한 경험으로 고객과의 모든 접점에서 필요로 하는 것들에 대한 다양한 해답을 제시합니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper partners_wrapper">
          <div className="badge" data-aos="fade-up">Our Partners</div>
          <div className="content_box">
            <h2 className="main_title" data-aos="fade-up" data-aos-anchor=".partners_wrapper .badge">
              고객 이상의 파트너 - 이너뷰는 <b>고객의 성공과 함께 성장</b>해 왔습니다.
            </h2>
            <div className="content">
              <p className="subtitle" data-aos="fade-up">
                브랜드의 철학을 이해하고 분석해 최선의 결과물을 만들어냅니다.
              </p>
              <ul className="partners_list">
                {isLoading
                    ? <></>
                    : data.map((item, key) => (
                  <li className="partner_item" data-aos="fade-up" key={key}><img src={item.logoimg} alt={item.title}/></li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </AboutCont>
  );
}

export default About;