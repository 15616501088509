import React from "react";
import styled from "styled-components";
import { MOBILE_WIDTH } from "../../constants";

const Textarea = styled.textarea`
  ${({width}) => `
    ${width ? `width: ${width}` : 'auto'};
  `};
  ${({height}) => `
    ${height ? `height: ${height}` : 'auto'};
  `};
  padding: 2.4rem;
  font-size: 1.8rem;
  line-height: 1.7;
  background: var(--black3);
  border: 1px solid var(--black3);
  resize: none;
  border-radius: 0;

  &:focus {
    border-color: var(--gray4);
  }

  &::placeholder {
    color: var(--gray5);
  }

  &::-webkit-input-placeholder {
    color: var(--gray5);
  }

  &:-ms-input-placeholder {
    color: var(--gray5);
  }

  // firefox 
  scrollbar-width: thin;
  scrollbar-color: var(--white1) var(--black3);

  // webkit
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--white1);
    border-radius: 10px;
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 16px;
    transform: scale(0.8125);
    transform-origin: left top;
    width: 123.07% !important;
    margin-right: -23.07%;
    margin-bottom: -60px;
    padding: 19px 20px;

    &::-webkit-scrollbar {
      width: 6px;
    }
  }
`;

const TextCount = styled.p`
  margin-top: 0.8rem;
  font-size: 1.8rem;
  font-weight: var(--fw_medium);
  color: var(--gray1);
  text-align: right;
  line-height: 1.7;

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    font-size: 13px;
  }
`;

const TextBox = React.memo(
  React.forwardRef(({
    id,
    className,
    disabled,
    readOnly,
    value,
    name = '',
    placeholder = '텍스트를 입력해주세요.',
    width = 'auto',
    height = 'auto',
    currentLength,
    maxLength,
    ...props
  }, ref) => {
  
    return (
      <>
        <Textarea
          ref={ref}
          id={id}
          className={className}
          disabled={disabled}
          readOnly={readOnly}
          value={value}
          name={name}
          placeholder={placeholder}
          width={width}
          height={height}
          maxLength={maxLength}
          {...props}
        >
        </Textarea>
        <TextCount>
          &#40;<span>{currentLength.toLocaleString()}</span>/{maxLength.toLocaleString()}&#41;
        </TextCount>
      </>
    );
  })
);

export default TextBox;
