import React from "react";
import styled from "styled-components";
import Button from "./Button";

const StyledButton = styled(Button)`
  width: 27.4rem;
  height: 5rem;

  &.on {
    border-color: var(--white1);
    color: var(--wthie1);
  }
`;

const WhiteButton = ({
  className,
  children,
  type,
  onClick
}) => {
  return (
    <StyledButton
      type={type}
      className={className}
      onClick={onClick}
      font={{
        size: '1.4rem',
        color: 'var(--gray1)',
        weight: 'var(--fw_medium)'
      }}
      border={{
        color: 'var(--gray1)',
        radius: '40px',
        width: '1px'
      }}
    >
      {children}
    </StyledButton>
  );
}

export default WhiteButton;
