import React, {useEffect} from "react";
import {LOCATION_MARKER} from "../constants";

const {kakao} = window;

const KakaoMap = ({className}) => {
  useEffect(() => {
    const container = document.getElementById("map");
    const options = {
      center: new kakao.maps.LatLng(37.476754, 126.983340),
      level: 3
    };

    const map = new kakao.maps.Map(container, options);

    const imageSrc = LOCATION_MARKER;
    const imageSize = new kakao.maps.Size(98, 69);
    const imageOption = {offset: new kakao.maps.Point(27, 69)};

    const markerPosition = new kakao.maps.LatLng(37.476754, 126.983340);
    const markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);

    const marker = new kakao.maps.Marker({
      position: markerPosition,
      image: markerImage
    });

    kakao.maps.event.addListener(marker, 'click', function() {
      window.open("https://map.kakao.com/?urlX=496305.0&urlY=1104804.0&name=%EC%9D%B4%EB%84%88%EB%B7%B0");
    });

    marker.setMap(map);

  }, [])

  return (
    <div
      id="map"
      className={className}
    >
    </div>
  );
}

export default KakaoMap;
