import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import {GetData} from "../apis";
import WhiteButton from "../components/button/WhiteButton";
import { responsiveFontMixin } from "../styles/mixins";
import { MOBILE_WIDTH, TABLET_WIDTH } from "../constants";
import { useMediaQuery } from "react-responsive";
import {useNavigate} from "react-router-dom";

const BOTTOM_TEXT_HEIGHT = 70;

const AniWheelMove = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(1px); }
  50% { transform: translateY(2px); }
  75% { transform: translateY(1px); }
  100% { transform: translateY(0); }
`;

const AniScrollArrowOpacity = keyframes`
  0% { opacity: 0%; }
  25% { opacity: 25%; }
  50% { opacity: 50%; }
  75% { opacity: 75%; }
  100% { opacity: 100%; }
`;

const HomeCont = styled.section``;

const PageScroll = styled(Swiper)`
  height: calc(100vh - var(--header));

  .page_scroll_item {
    height: 100%;
  }
`;

const AniMainIntro = keyframes`
  0% {
    transform: translateY(30%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const AniTextPoint = keyframes`
  0% {opacity: 1;}
  100% {opacity: 0;}
`;

const HomeIntro = styled(SwiperSlide)`
  position: relative;

  .intro_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-right: 9rem;
  }

  .main_top_text {
    flex: 0 1 auto;
    position: relative;
    width: 100%;
    display: none;
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 1px;
      background: var(--gray1);
    }
  }

  .main_slogan, .main_introduction {
    padding-bottom: 8rem;
    animation: ${AniMainIntro} 1.2s forwards;
    opacity: 0;
  }

  .main_slogan {
    flex: 0 1 auto;

    p {
      ${responsiveFontMixin(144, 56, 1920, 360)};
      font-weight: var(--fw_bold);
      color: var(--main1);
      letter-spacing: 0.15em;
      vertical-align: middle;

      span {
        position: relative;
        letter-spacing: inherit;
        background: linear-gradient(323.67deg, #D6E2FF 12.03%, #FFFFFF 113.12%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        display: inline-block;

        &.stroke {
          color: var(--black1);
          text-shadow: -1px -1px 0 var(--main1), 1px -1px 0 var(--main1), -1px 1px 0 var(--main1), 1px 1px 0 var(--main1);
        }

        &.bgtext::after {
          content: attr(data-text);
          position: absolute;
          top: 0;
          left: 0;
          text-shadow: -1px -1px 0 var(--main1), 1px -1px 0 var(--main1), -1px 1px 0 var(--main1), 1px 1px 0 var(--main1);
          color: var(--black1);
          animation: ${AniTextPoint} 2s forwards;
          animation-delay: 1s;
        }
      }
    }
  }

  .main_introduction {
    flex: 0 1 auto;
    margin-left: 6%;
    animation-delay: 0.5s;

    p {
      font-size: 2rem;
      font-weight: var(--fw_thin);
      line-height: 1.7;
      letter-spacing: -0.02em;
    }

    button {
      width: 21.2rem;
      margin-top: 3.2rem;
      color: var(--white1);
      transition: border var(--trans_time);

      &:hover {
        border-color: var(--white1);
      }
    }
  }

  .scrolldown_icon {
    position: absolute;
    bottom: 9rem;
    left: 50%;
    transform: translateX(-50%);

    .pc_scroll {

      &::before {
        content: 'SCROLL';
        font-size: 9px;
        font-weight: var(--fw_300);
      }

      .mouse_icon {
        margin: 6px auto 0;
        width: 18px;
        height: 28px;
        border: 1px solid var(--white1);
        border-radius: 9px;
        position: relative;

        .wheel_icon {
          width: 2px;
          height: 3px;
          background-color: var(--white1);
          border-radius: 1px;
          position: absolute;
          top: 6px;
          left: 7px;
          animation: ${AniWheelMove} 0.8s ease-out infinite;
        }
      }

      .scroll_arrows {
        margin-top: 4px;

        .scroll_arrow {
          margin: 0 auto 0;
          width: 9px;
          height: 9px;
          border-right: 2px solid var(--white1);
          border-bottom: 2px solid var(--white1);
          transform: rotate(45deg);
          animation: ${AniScrollArrowOpacity} 1s infinite alternate;

          &:nth-child(1) {
            animation-delay: 0;
          }
          &:nth-child(2) {
            animation-delay: 0.1s;
          }
          &:nth-child(3) {
            animation-delay: 0.2s;
          }
        }
      }
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {

    .intro_inner {
      padding-right: 0;
    }

    .main_introduction {
      margin-left: 3%;

      p {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .intro_inner {
      display: block;
      height: auto;
      padding: 0 6.7%;
    }

    .main_top_text {
      display: block;
    }

    .main_slogan, .main_introduction {
      padding: 0;
    }

    .main_slogan {
      p {
        line-height: 1.15;
        letter-spacing: 0.13em;
      }
    }

    .main_introduction {
      width: 100%;
      margin: 32px 0 0;

      button {
        width: 191px;
        height: 42px;
        border-color: var(--white1);
      }
    }

    .scrolldown_icon {
      position: static;
      padding-bottom: 40px;
      text-align: center;
      transform: none;

      .mo_scroll {
        .mo_scroll_arrow {
          animation: ${AniScrollArrowOpacity} 1s infinite alternate;

          &:nth-child(1) {
            animation-delay: 0;
          }
          &:nth-child(2) {
            animation-delay: 0.1s;
          }
          &:nth-child(3) {
            animation-delay: 0.2s;
          }
        }
      }
    }
  }
`;

const AniArrowWave = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(20%);
  }

  100% {
    transform: translateX(0);
  }
`;

const AniArrowOpacity = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

const Div = styled(SwiperSlide)`
  .drag_cursor_container {
    position: relative;
    height: calc(100% - ${BOTTOM_TEXT_HEIGHT}px);
    cursor: url(/img/cursor_drag.svg) 60 60, grab;
  }

  .notice_swipe {
    display: none;
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 24px;
    animation: ${AniArrowWave} 1.5s infinite;

    span {
      animation: ${AniArrowOpacity} 1s infinite alternate;
      
      &:nth-child(1) {
        animation-delay: 0.15s;
      }

      &:nth-child(2) {
        animation-delay: 0.3s;
      }

      &:nth-child(3) {
        animation-delay: 0.45s;
      }
    }
  }

  .bottom_text_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${BOTTOM_TEXT_HEIGHT}px;
    padding: 0 4.2%;
    border-top: 1px solid var(--gray1);

    p {
      font-size: 12px;
      color: #949494;

      br {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    .notice_swipe {
      display: block;
    }

    .bottom_text_container {
      justify-content: center;

      p {
        font-size: 11px;
        text-align: center;
        line-height: 1.3;

        &:nth-child(1),
        &:nth-child(3) {
          display: none;
        }

        br {
          display: block;
        }
      }
    }
  }
`;

// const DragCusor = styled.div`
//   position: absolute;
//   z-index: 99;
//   transform: translate(-50%, calc(-50% - var(--header)));
//   pointer-events: none;
//   transition: opacity 0.15s;
//   width: 12rem;
//   height: 12rem;

//   &:not(.on) {
//     opacity: 0;
//   }
// `;

const SlideCardCont = styled(Swiper)`
  height: 100%;
  overflow-x: hidden;

  .swiper-pagination-progressbar {
    background: transparent;

    .swiper-pagination-progressbar-fill {
      background: var(--white1);
    }
  }
`;

const MainCard = styled(SwiperSlide)`
  &.slide_card {
    position: relative;
    width: 49.8rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(n+2) {
      border-left: 1px solid var(--gray1);
    }

    &:hover {
      .card_color_img {
        clip-path: circle(50% at 50% 50%);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(${props => props.bgimg || ''});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.15;
    }

    .card_inner {
      flex: 1 0 auto;
      position: relative;
      z-index: 2;
      text-align: center;

      .company_title {
        position: relative;
        width: 220px;
        padding-bottom: 9px;
        margin: 0 auto;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 28px;
          height: 3px;
          background: var(--white1);
        }
      }

      .company_info {
        max-width: 70%;
        margin: 11px auto 0;
        font-size: 1.8rem;
        font-weight: var(--fw_medium);
        line-height: var(--lh_base);
        opacity: 0.7;
      }

      .work_info {
        margin-top: 5px;
        font-size: 1.4rem;
        font-weight: var(--fw_medium);
        opacity: 0.5;
      }

      .work_date {
        margin-top: 12px;
        font-size: 1.3rem;
        font-weight: var(--fw_medium);
        opacity: 0.5;
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    &.slide_card {
      width: 92%;

      &:hover {
        .card_color_img {
          clip-path: circle(0% at 50% 50%);
        }
      }

      &.swiper-slide-active {
        .card_color_img {
          clip-path: circle(50% at 50% 50%);
        }
      }

      .card_inner {
        .company_title {
          padding-bottom: 0;
        }

        .company_info {
          font-size: 13px;
        }

        .work_info {
          font-size: 13px;
          font-weight: var(--fw_base);
        }
  
        .work_date {
          font-size: 13px;
          font-weight: var(--fw_base);
        }
      }
    }
  }
`;

const CardColorImg = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  clip-path: circle(0% at 50% 50%);
  transition: 0.5s;
`;

const MainMoreButton = styled(WhiteButton)`
  width: 21.2rem;
  margin-top: 60px;
  color: var(--white1);
  transition: var(--trans_time);
  cursor: none;

  &:hover {
    font-weight: var(--fw_bold);
    color: var(--black1);
    background: var(--main1);
    border-color: var(--main1);
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    width: 191px;
    height: 42px;
    margin-top: 24px;
    font-size: 14px;
    font-weight: var(--fw_bold);
    color: var(--black1);
    background: var(--main1);
    border-color: var(--main1);
  }
`;

const Home = () => {
  const navigate = useNavigate();
  const {data, isLoading, isError} = GetData("getMain");

  const [isSlideChanged, setIsSlideChanged] = useState(true);

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  return (
    <HomeCont>
      <PageScroll
        slidesPerView={1}
        direction={"vertical"}
        mousewheel={true}
        modules={[Mousewheel]}
        allowTouchMove={true}
        breakpoints={{
          751: {
            allowTouchMove: false
          }
        }}
      >
        <HomeIntro className="page_scroll_item">
          <p className="main_top_text">We look into your mind</p>
          <div className="intro_inner">
            <div className="main_slogan">
              <p>
                <span className="bgtext" data-text="IN">IN</span>
                <span className="stroke">SIGHT,</span>
              </p>
              <p>
                <span className="bgtext" data-text="NER">NER</span>
                <span className="stroke">VE,</span>
              </p>
              <p>
                <span className="bgtext" data-text="VIEW">VIEW</span>
              </p>
            </div>
            <div className="main_introduction">
              <p>
                우리는 고객과 시장을 <b>통찰력있게</b> 봅니다.<br/>
                누구도 안 된다고 말할 때, 우리는 가능성을 향해<br/>
                <b>대담하게 도전</b>하며, <b>넓은 견해</b>로 바라보고<br/>
                창의적이면서 논리적인 서비스를 만듭니다.
              </p>
              <WhiteButton
                  onClick={() => navigate('/about')}
              >
                View More
              </WhiteButton>
            </div>
          </div>
          <div className="scrolldown_icon">
            {isMobile ? (
              <div className="mo_scroll">
                <div className="mo_scroll_arrow"><img src="/img/scroll_down_arr.svg" alt="아래를 가리키는 화살표" /></div>
                <div className="mo_scroll_arrow"><img src="/img/scroll_down_arr.svg" alt="아래를 가리키는 화살표" /></div>
                <div className="mo_scroll_arrow"><img src="/img/scroll_down_arr.svg" alt="아래를 가리키는 화살표" /></div>
              </div>
            ) : (
              <div className="pc_scroll">
                <div className="mouse_icon">
                  <div className="wheel_icon"></div>
                </div>
                <div className="scroll_arrows">
                  <div className="scroll_arrow"></div>
                  <div className="scroll_arrow"></div>
                  <div className="scroll_arrow"></div>
                </div>
              </div>
            )}
          </div>
        </HomeIntro>
        <Div className="page_scroll_item">
          <div
            className="drag_cursor_container"
            //onMouseMove={(e) => {handleMouseMove(e)}}  
          >
            {/* <DragCusor
              className={!isButtonHover ? 'on' : ''}
              style={{
                top: mousePosition.top + 'px',
                left: mousePosition.left + 'px',
              }}
            >
              <img src="/img/main_cursor.svg" alt="Drag 라고 적힌 원형" />
            </DragCusor> */}
            {isSlideChanged && (
              <div className="notice_swipe">
                <span><img src="/img/main_swipe_1.svg" alt="오른쪽을 가리키는 화살표" /></span>
                <span><img src="/img/main_swipe_2.svg" alt="오른쪽을 가리키는 화살표" /></span>
                <span><img src="/img/main_swipe_2.svg" alt="오른쪽을 가리키는 화살표" /></span>
              </div>
            )}
            <SlideCardCont
              className="slide_card_container"
              slidesPerView={"auto"}
              modules={[Pagination]}
              pagination={{
                type: "progressbar",
              }}
              onSlideNextTransitionStart={() => {
                isSlideChanged && setIsSlideChanged(false);
              }}
            >
              {isLoading ? <></>
                  : data.map((data, index) => (
                  <MainCard
                    key={index}
                    className="slide_card"
                    bgimg={isMobile ? data.mainmoimg : data.mainpcimg}
                  >
                    <CardColorImg
                      className="card_color_img"
                      style={{backgroundImage: `url(${isMobile ? data.mainmoimg : data.mainpcimg})`}}
                    ></CardColorImg>
                    <div className="card_inner">
                      <p className="company_title"><img src={data.logoimg} alt={data.client + ' 로고'}/></p>
                      <p className="company_info">{data.companyType}</p>
                      <p className="work_info">{data.categoryType}</p>
                      <p className="work_date">{data.date}</p>
                      <MainMoreButton
                        onClick={() => navigate('/experience/detail/' + data.id)}
                      >
                        View More
                      </MainMoreButton>
                    </div>
                  </MainCard>
                ))
              }
            </SlideCardCont>
          </div>
          <div className="bottom_text_container">
            <p>What you want</p>
            <p>© COPYRIGHT {new Date().getFullYear()} BY INNERVIEW.&nbsp;<br/>ALL RIGHT RESERVED</p>
            <p>Beyond your expectation</p>
          </div>
        </Div>
      </PageScroll>
    </HomeCont>
  );
}

export default Home;
