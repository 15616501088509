import React, {useEffect, useState} from "react";
import styled, { keyframes } from "styled-components";
import {GetData, PostData} from "../apis";
import SelectBox from "../components/form/SelectBox";
import InputBox from "../components/form/InputBox";
import TextBox from "../components/form/TextBox";
import WhiteButton from "../components/button/WhiteButton";
import ContentTitle from "../components/ContentTitle";
import PrivacyCheckWrap from "../components/PrivacyCheckWrap";
import { MOBILE_WIDTH, SCROLL_DELAY, SCROLL_DURATION, SCROLL_OFFSET, TABLET_WIDTH } from "../constants";
import PrivacyInfoModal from "../components/PrivacyInfoModal";
import AlertModal from "../components/AlertModal";
import InputFile from "../components/form/InputFile";
import {makePhoneFormat} from "../utils";
import {loadCaptchaEnginge, validateCaptcha} from 'react-simple-captcha';
import {unstable_usePrompt, useOutletContext} from "react-router-dom";
import Captcha from "../components/Captcha";
import AOS from "aos";
import 'aos/dist/aos.css';
import FloatingRotateButton from "../components/button/FloatingRotateButton";
import { useMediaQuery } from "react-responsive";

const RecruitCont = styled.section`
  padding-bottom: 10.4%;

  .banner {
    position: relative;
    height: 59vw;
    padding: 5.7% 4.2% 6.9%;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(/img/recruit_banner_bg.jpg) center/cover no-repeat;
    }

    .banner_inner {
      position: relative;
      height: 100%;

      h2 {
        font-size: 7.6rem;
        font-weight: var(--fw_medium);
        line-height: 1.3;
  
        span {
          display: block;
          margin-bottom: 2rem;
          font-size: 2.8rem;
          font-weight: var(--fw_base);
          line-height: 1.5;
        }
      }
  
      p {
        margin-top: 4.3%;
        font-size: 2rem;
        font-weight: var(--fw_thin);
        line-height: 1.7;

        .mo_br {
          display: none;
        }
      }
    }
  }

  .content_wrapper {
    padding : 0 4.2%;
  }

  .recruit_wrapper {
    margin-top: 7.5%;
    position: relative;
    padding-top: 6.2%;
    padding-bottom: 17.2%;
    display: flex;
    align-items: flex-start;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url(/img/recruit_list_bg.png) top right/100% auto no-repeat;
      mix-blend-mode: soft-light;
    }

    h2 {
      flex: 0 0 auto;
      font-weight: var(--fw_thin);
    }

    .recruit_list {
      flex: 1 1 auto;
      padding-left: 8.5%;
      
      .recruit_item {
        padding: 5rem 0;
        border-top: 1px solid var(--gray1);
        overflow: hidden;

        &:last-child {
          border-bottom: 1px solid var(--gray1);
        }

        .position {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          user-select: none;

          .position_title {
            padding-left: 4rem;
            font-size: 6rem;
            font-weight: var(--fw_medium);
          }
          
          .position_content {
            display: flex;
            align-items: center;
            padding-right: 16px;

            .career {
              margin-right: 4rem;
              font-size: 2.4rem;
              font-weight: var(--fw_bold);
              color: var(--main1);
            }

            .hiring_badge {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 18rem;
              height: 5.2rem;
              margin-right: 5.4rem;
              font-size: 2rem;
              color: var(--main1);
              border: 1px solid var(--main1);
              border-radius: 50%;
            }

            .hiring_arr_img {
              transform: rotate(180deg);
              opacity: 0.5;
              transition: transform var(--trans_time);
            }
          }
        }

        .qualification {
          transition: var(--trans_time);
          transform: translateY(0);
          max-height: 500px;
          overflow: hidden;

          .content {
            display: flex;
            padding: 4.1% 3.3%;
            margin-top: 7rem;
            background: var(--black2);

            > div {
              flex: 1 1 50%;

              &:nth-of-type(2) {
                margin-left: 2rem;
                flex: 1 1 71%;
              }

              p {
                font-size: 2.1rem;
                font-weight: var(--fw_bold);
              }

              ul {
                padding-left: 1rem;
                margin-top: 3rem;

                li {
                  display: flex;
                  font-size: 2.1rem;
                  font-weight: var(--fw_thin);
                  line-height: var(--lh_base);

                  &:nth-child(n+2) {
                    margin-top: 2rem;
                  }

                  &::before {
                    content: '';
                    flex: 0 0 auto;
                    display: inline-block;
                    width: 5px;
                    height: 5px;
                    margin: 9px 15px 0 0;
                    background: var(--white1);
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }

        &:not(.on) {
          .position {
            .position_content {
              .hiring_arr_img {
                transform: rotate(0);
                opacity: 1;
              }
            }
          }

          .qualification {
            max-height: 0;
          }
        }
      }
    }
  }

  .culture_wrapper {
    h2 {
      line-height: 1.7;

      span {
        line-height: 1.4;
      }
    }

    .culture_list {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      width: calc(100% + 20px);
      transform: translateX(-10px);
      margin-top: 50px;

      .culture_item {
        position: relative;
        flex: 0 1 auto;
        width: calc(20% - 20px);
        padding-bottom: 18.4%;
        margin: 0 10px 20px;
        text-align: center;
        background: var(--black2);

        .culture_item_inner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          padding: 0 10%;
        }

        .culture_title {
          margin-top: 10px;
          font-size: 2.4rem;
          font-weight: var(--fw_bold);
          line-height: 1.7;
        }

        .culture_description {
          margin-top: 12px;
          font-size: 1.8rem;
          font-weight: var(--fw_thin);
          color: var(--gray2);
          line-height: 1.7;
          white-space: pre-line;
        }
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .recruit_wrapper {
      .recruit_list {
        padding-left: 5.5%;
      }
    }

    .culture_wrapper {
      .culture_list {
        .culture_item {
          width: calc(25% - 20px);
          padding-bottom: 23%;
        }
      }
    }
  }

  @media screen and (max-width: 1366px) {
    .culture_wrapper {
      .culture_list {
        width: calc(100% + 12px);
        transform: translateX(-6px);

        .culture_item {
          width: calc(25% - 12px);
          margin: 0 6px 12px;
        }
      }
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    padding-bottom: 12.7%;

    .banner {
      height: 781px;
      padding: 4.3% 3% 5.4%;

      &::before {
        width: 1327px;
        left: 50%;
        transform: translateX(-58%);
      }

      .banner_inner {
        h2 {
          font-size: 6rem;

          span {
            font-size: 2.2rem;
          }
        }

        p {
          margin-top: 3%;
          font-size: 1.8rem;

          .mo_br {
            display: block;
          }
        }
      }
    }

    .recruit_wrapper {
      display: block;

      .recruit_list {
        padding-left: 0;
        margin-top: 52px;

        .recruit_item {
          .position {

            .position_title {
              font-size: 4.6rem;
            }

            .position_content {
              .career {
                font-size: 2rem;
              }

              .hiring_badge {
                width: 16rem;
                height: 4.8rem;
                font-size: 1.8rem;
              }

              .hiring_arr_img {
                width: 6rem;
              }
            }
          }

          .qualification {
            .content {
              margin-top: 5rem;

              > div {
                p {
                  font-size: 1.9rem;
                }

                ul {
                  margin-top: 2.6rem;

                  li {
                    font-size: 1.9rem;

                    &:nth-child(n+2) {
                      margin-top: 1.4rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .culture_wrapper {
      .culture_list {
        width: calc(100% + 8px);
        transform: translateX(-4px);

        .culture_item {
          width: calc(33.3% - 8px);
          padding-bottom: 31.5%;
          margin: 0 4px 8px;
        }
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    padding-bottom: 152px;

    .banner {
      padding: 60px 21px 87px;

      .banner_inner {
        h2 {
          font-size: 32px;

          span {
            margin-bottom: 8px;
            font-size: 18px;
          }
        }

        p {
          margin-top: 40px;
          font-size: 14px;
        }
      }
    }

    .recruit_wrapper {
      margin: 0;
      padding: 120px 0 72px 24px;

      &::before {
        display: none;
      }

      .recruit_list {
        .recruit_item {
          padding: 20px 0;

          .position {

            .position_title {
              padding-left: 0;
              font-size: 22px;
            }

            .position_content {
              padding-right: 24px;

              .career {
                margin-right: 12px;
                font-size: 11px;
              }

              .hiring_badge {
                width: 100px;
                height: 28px;
                margin-right: 10px;
                font-size: 12px;
                font-weight: var(--fw_medium);
              }

              .hiring_arr_img {
                width: 28px;
              }
            }
          }

          .qualification {
            max-height: 600px;
            transition: 0.5s;

            .content {
              display: block;
              padding: 24px;
              margin-top: 20px;

              > div {
                &:nth-child(n+2) {
                  margin: 20px 0 0;
                }

                p {
                  font-size: 14px;
                }

                ul {
                  padding-left: 7px;
                  margin-top: 16px;

                  li {
                    font-size: 14px;
                    line-height: 1.5;

                    &:nth-child(n+2) {
                      margin-top: 8px;
                    }

                    &::before {
                      width: 4px;
                      height: 4px;
                      margin: 6px 10px 0 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .culture_wrapper {
      .culture_list {
        margin-top: 32px;

        .culture_item {
          width: calc(50% - 8px);
          padding-bottom: 48.9%;

          .icon_box {
            width: 28px;
          }

          .culture_title {
            margin-top: 8px;
            font-size: 14px;
          }

          .culture_description {
            margin-top: 0;
            font-size: 12px;
          }
        }
      }
    }
  }
`;

const DRAWER_INNER_WIDTH = '58.8%';
const DRAWER_INNER_WIDTH2 = '68%';

const ApplyDrawer = styled.div`
  position: fixed;
  z-index: calc(var(--header_index) + 1);
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  height: 100vh;
  padding: 0;
  background: rgba(0, 0, 0, 0.54);

  &.content_wrapper {
    padding: 0;
  }
  .drawer_outer {
    flex: 0 1 auto;
    width: calc(100% - ${DRAWER_INNER_WIDTH});
    height: 100%;
  }

  .drawer_inner {
    flex: 1 1 auto;
    width: ${DRAWER_INNER_WIDTH};
    height: 100%;
    padding: 3.1% 4.2% 4.4%;
    background-color: var(--black1);
    transition: transform var(--trans_time);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .close_button_wrap {
      margin-bottom: 4px;
      text-align: right;

      .close_button {
        position: relative;
        width: 26px;
        height: 26px;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(135deg);
          width: 23px;
          height: 1px;
          background: var(--white1);
        }

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 23px;
          height: 1px;
          background: var(--white1);
        }
      }
    }

    .apply_drawer_title {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      h2 {
        font-weight: var(--fw_thin);
  
        b {
          font-weight: var(--fw_medium);
        }
      }

      > span {
        display: inline-block;
        padding-bottom: 0.8rem;
        font-size: 1.8rem;
        font-weight: var(--fw_medium);
        line-height: 1.7;
      }
    }
    
    .box {
      * {
        font-family: var(--ff_kor);
      }

      .box_title {
        font-size: 2.2rem;
        font-weight: var(--fw_medium);
        margin-bottom: 2.4rem;
      }
    }
    
   

    .apply_input_wrapper {
      margin-top: 8rem;

      .text_input_wrap {
        display: flex;
        flex-wrap: wrap;

        > * {
          flex: 1 1 auto;
          width: calc(50% - 15px);
          margin-top: 4.8rem;

          &:nth-child(2n) {
            margin-left: 18px;
          }

          input {
            width: 100%;
          }
        }
      }

      .textarea_box {
        width: 100%;
        margin-top: 8.8rem;

        .text_count {
          margin-top: 0.8rem;
          font-size: 1.8rem;
          font-weight: var(--fw_medium);
          color: var(--gray1);
          text-align: right;
        }
      }

      .input_file_box {
        width: 100%;
        margin-top: 8.8rem;

        .box_title {
          margin-bottom: 1rem;
        }

        .file_upload_notice {
          margin-bottom: 1.6rem;
        }
      }
      
      .privacy_wrap {
        margin-top: 8.8rem;
      }
      
      .captcha_box {
        margin-top: 8rem;

        .captcha_input_wrap {
          margin-top: 2.4rem;
        }
      }

      .apply_send_button {
        display: block;
        margin: 8rem auto 0;
      }
    }
  }

  &:not(.on) {
    opacity: 0;
    visibility: hidden;

    .drawer_inner {
      transform: translateX(100%);
    }
  }

  @media screen and (max-width: 1366px) {
    .drawer_outer {
      width: calc(100% - ${DRAWER_INNER_WIDTH2});
    }

    .drawer_inner {
      width: ${DRAWER_INNER_WIDTH2};
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    .drawer_outer {
      display: none;
    }

    .drawer_inner {
      width: 100%;
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    .drawer_inner {
      padding: 10px 24px 160px;

      .close_button_wrap {
        margin-bottom: 20px;
      }

      .apply_drawer_title {
        > span {
          display: none;
        }
      }

      .box {
        .box_title {
          font-size: 14px;
          margin-bottom: 11px;
        }
      }

      .apply_input_wrapper {
        margin-top: 40px;

        .text_input_wrap {
          display: block;

          > * {
            width: 100%;
            margin-top: 40px;

            &:nth-child(2n) {
              margin-left: 0;
            }
          }
        }

        .textarea_box {
          margin-top: 72px;
        }

        .input_file_box {
          margin-top: 72px;

          .file_upload_notice {
            margin-bottom: 8px;
            line-height: 1.3;
          }
        }

        .privacy_wrap {
          margin-top: 48px;
        }

        .captcha_box {
          margin-top: 40px;
        }

        .apply_send_button {
          margin-top: 80px;
        }
      }
    }
  }
`;

const AniFlowText = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
`;

const FlowTextContainer = styled.div`
  padding-top: 8rem;
  background: linear-gradient(180deg, #040402 -3.44%, rgba(4, 4, 2, 0) 96.56%);

  .flow_text_wrapper {
    position: relative;
    height: 7.4rem;
    overflow: hidden;
    user-select: none;

    &:nth-child(n+2) {
      margin-top: 2.6rem;
    }

    &:nth-child(2n) {
      .flow_text_list {
        animation-direction: reverse;
      }
    }

    &:nth-child(2) {
      .flow_text_list {
        animation-duration: 30s;
      }
    }

    &:nth-child(3) {
      .flow_text_list {
        animation-duration: 27s;
      }
    }

    &:nth-child(4) {
      .flow_text_list {
        animation-duration: 27s;
      }
    }

    &:nth-child(5) {
      .flow_text_list {
        animation-duration: 33s;
      }
    }

    &:nth-child(6) {
      .flow_text_list {
        animation-duration: 33s;
      }
    }

    .flow_text_list {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      white-space: nowrap;
      animation: ${AniFlowText} 30s linear infinite;

      .flow_text_item {
        width: auto;
        margin-right: 4rem;
        font-size: 7rem;
        font-weight: var(--fw_medium);
        color: #3e3e3e;

        &.point {
          font-weight: var(--fw_bold);
          color: var(--main1);
        }
      }
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    .flow_text_wrapper {
      height: 5.7rem;

      &:nth-child(n+2) {
        margin-top: 1.8rem;
      }

      .flow_text_list {
        .flow_text_item {
          font-size: 5.6rem;
        }
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    padding-top: 0;
    
    .flow_text_wrapper {
      height: 34px;

      &:nth-child(n+2) {
        margin-top: 10px;
      }

      .flow_text_list {
        .flow_text_item {
          font-size: 32px;
        }
      }
    }
  }
`;

const cultureList = [
  {
    icon: '/img/culture_welfare.svg',
    title: '법적복리후생',
    description: '4대 보험 및 퇴직연금 제도\n기본연차 자유롭게 사용'
  },
  {
    icon: '/img/culture_off.svg',
    title: '장기근속휴가',
    description: '5년 리프레시 휴가'
  },
  {
    icon: '/img/culture_lunch.svg',
    title: '점심제공',
    description: '점심 도시락 제공'
  },
  {
    icon: '/img/culture_taxi.svg',
    title: '택시비 지원',
    description: '야근 시, 택시비 지원'
  },
  {
    icon: '/img/culture_mon.svg',
    title: '월요일 오후 1시 출근',
    description: '월요병 없는 월요일'
  },
  {
    icon: '/img/culture_develop.svg',
    title: '자기개발 지원',
    description: '(직무) 교육비 지원\n도서 구매 지원'
  },
  {
    icon: '/img/culture_environment.svg',
    title: '쾌적한 근무환경',
    description: '안마기 비치\n공기청정기, 가습기 비치'
  },
  {
    icon: '/img/culture_coffee.svg',
    title: '커피바 운영',
    description: '커피 무한 제공'
  },
  {
    icon: '/img/culture_clothes.svg',
    title: '자유로운 복장',
    description: '자유복장'
  },
  {
    icon: '/img/culture_appellation.svg',
    title: '상호 존중 호칭',
    description: '‘님’문화 지향'
  },
];

const flowTextArr1 = [
  {
    title: 'Open-minded',
    point: false
  },
  {
    title: '명확함',
    point: true
  },
  {
    title: 'E-commerce',
    point: false
  },
  {
    title: '성장하는',
    point: false
  },
  {
    title: 'Stategy',
    point: true
  },
  {
    title: '변화와 혁신',
    point: false
  },
];

const flowTextArr2 = [
  {
    title: '재미있는',
    point: false
  },
  {
    title: '젊음',
    point: false
  },
  {
    title: 'Publishing',
    point: false
  },
  {
    title: '전문가다운',
    point: true
  },
  {
    title: 'Multisense',
    point: false
  },
  {
    title: '유연한',
    point: false
  },
  {
    title: '트렌디한',
    point: false
  },
];

const flowTextArr3 = [
  {
    title: '도전',
    point: false
  },
  {
    title: 'Trendsetter',
    point: false
  },
  {
    title: 'Competence',
    point: false
  },
  {
    title: '감각',
    point: false
  },
  {
    title: '가능성이 무궁무진한',
    point: true
  },
  {
    title: 'Design',
    point: false
  },
];

const flowTextArr4 = [
  {
    title: '에너지가 넘치는',
    point: true
  },
  {
    title: 'Technology',
    point: false
  },
  {
    title: '호기심 넘치는',
    point: true
  },
  {
    title: 'Communication',
    point: false
  },
  {
    title: '리서치',
    point: false
  },
];

const flowTextArr5 = [
  {
    title: '자유복장',
    point: false
  },
  {
    title: 'Development',
    point: false
  },
  {
    title: '프로다움',
    point: false
  },
  {
    title: 'Analytical Power',
    point: false
  },
  {
    title: '책임감',
    point: true
  },
  {
    title: 'Sensible Design',
    point: false
  },
];

const flowTextArr6 = [
  {
    title: '시너지',
    point: false
  },
  {
    title: 'content design',
    point: false
  },
  {
    title: '사당역세권',
    point: false
  },
  {
    title: '자유로운',
    point: true
  },
  {
    title: 'Back-end',
    point: false
  },
  {
    title: '오픈마인드',
    point: false
  },
];

const Recruit = () => {

  const { footerRef } = useOutletContext();

  useEffect(() => {
    AOS.init({
      duration: SCROLL_DURATION,
      offset: SCROLL_OFFSET,
      delay: SCROLL_DELAY,
    });
  },[]);

  // Captcha
  const [captcha, setCaptcha] = useState('');
  const handleCaptcha = (e) => {
    setCaptcha(e.target.value);
  }

  // toggle, drawer, modal
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [toggleState, setToggleState] = useState([false, false, false, false]);
  const [privacyModalShow, setPrivacyModalShow] = useState(false);
  const [alertModalShow, setAlertModalShow] = useState(false);
  const closeModal = () => {
    if(privacyModalShow) setPrivacyModalShow(false);
    if(alertModalShow) setAlertModalShow(false);
  }

  // data
  const hiring = GetData('getHiring', {});
  const hiringCategory = GetData("getCategory", { query: { pageName: "recruit" } });

  // .qualification 높이값 구하는 부분 (다른 방식으로 처리했으나, 추후 필요할 가능성이 있어 주석으로 남김)
  // const qualRef = useRef([]);
  // if (!hiring.isLoading) {
  //   qualRef.current = hiring.data.map((_, index) => qualRef.current[index] ?? createRef());
  // }
  // useEffect(()=>{
  //   if (!hiring.isLoading) {
  //     setToggleState([false, false, false, false])
  //   }
  // }, [qualRef.current.length]);

  // Apply now
  const { trigger } = PostData('postApplySend', {
    onSuccess: () => {
      console.log("success")
      setAlertModalShow(true);
      setDrawerOpen(false);
      setApplyData(applyDataInit);
      setCaptcha('');
      setInitSelect(true);
      setInitFIle(true);
      setIsSomethingChanged(false);
    },
    onError: () => {
      console.log("error")
    }
  });

  const applyDataInit = {
    name: '',
    phone: '',
    email: '',
    portfolioUrl: '',
    desc: '',
    agree: 0,
    CategoryId: 0,
    file: null
  }
  const [selectCategory, setSelectCategory] = useState('');
  const [applyData, setApplyData] = useState(applyDataInit);
  const [applyDataValid, setApplyDataValid] = useState(false);
  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [initSelect, setInitSelect] = useState(false);
  const [initFile, setInitFIle] = useState(false);

  useEffect(() => {
    if (hiringCategory.isLoading) return;
    const category = hiringCategory.data.find(item => item.type === selectCategory)
    if (!category) return;
    handleInputData("CategoryId", category.id)
  }, [selectCategory]);

  const handleInputData = (key, value) => {
    if (key === "phone") {
      value = makePhoneFormat(value)
    }
    applyData[key] = value
    setApplyData({...applyData})
    if (drawerOpen) {
      setIsSomethingChanged(true);
    }
  }

  useEffect(() => {
    handleApplyData()
  }, [applyData, selectCategory, captcha])
  const handleApplyData = () => {
    if (applyData.name !== '' && applyData.phone !== ''  && applyData.agree && applyData.CategoryId && captcha !== '') {
      setApplyDataValid(true)
    } else {
      setApplyDataValid(false)
    }
  }
  const handleSendData = () => {
    const regexPhone = /^(010)-\d{4}-\d{4}$/;
    if (!regexPhone.test(applyData.phone)) {
        alert('올바른 형식의 휴대전화번호를 입력해 주세요.');
        return false;
    }
    const regexEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,}$/;
    if (applyData.email && !regexEmail.test(applyData.email)) {
        alert('올바른 형식의 이메일 주소를 입력해 주세요.');
        return false;
    }
    if (!validateCaptcha(captcha)) {
        alert('자동입력방지 문자가 일치하지 않습니다. 다시 입력해 주세요.');
        loadCaptchaEnginge(4);
        setCaptcha('');
        return false;
    }

    const formData = new FormData();
    for (let key in applyData) {
        formData.append(key, applyData[key]);
    }
    const arg = {
      data: formData,
      config: {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
      }
    }
    trigger(arg)
  }

  const handleCLickDimmer = () => {
    if (isSomethingChanged) {
      if (window.confirm("작성중인 내용이 있습니다. 페이지를 벗어나시겠습니까?\n작성된 내용은 저장되지 않습니다.")) {
        setDrawerOpen(false);
        setApplyData(applyDataInit);
        setCaptcha('');
        setInitSelect(true);
        setInitFIle(true);
        setIsSomethingChanged(false);
      }
    } else {
      setDrawerOpen(false);
      setInitSelect(true);
      setInitFIle(true);
      setIsSomethingChanged(false);
    }
  }

  unstable_usePrompt({
    when: isSomethingChanged,
    message: "작성중인 내용이 있습니다. 페이지를 벗어나시겠습니까?\n작성된 내용은 저장되지 않습니다.",
  });

  const isMobile = useMediaQuery({ query: `(max-width: ${ MOBILE_WIDTH }px)` });

  return (
    <>
      <ApplyDrawer
        className={"content_wrapper" + (drawerOpen ? " on" : "")}
      >
        <div
          className="drawer_outer"
          onClick={handleCLickDimmer}>
        </div>
        <div className="drawer_inner">
          <div className="close_button_wrap">
            <button
              type="button"
              onClick={() => setDrawerOpen(!drawerOpen)}
              className="close_button"
            ></button>
          </div>
          <div className="apply_drawer_title">
            <ContentTitle
              subtitle="Apply now"
            >
              Tell us about&nbsp;<b>yourself</b>
            </ContentTitle>
            <span><i className="require">*</i>&nbsp;필수 작성 항목</span>
          </div>
          <div className="apply_input_wrapper">
            <div className="box">
              <p className="box_title">Position&nbsp;<i className="require">*</i></p>
              <SelectBox
                optionList={hiringCategory.isLoading ? [] : hiringCategory.data.map((item) => item.type)}
                placeholder="지원 포지션을 선택해주세요."
                setData={setSelectCategory}
                initSelect={initSelect}
                setInitSelect={setInitSelect}
              ></SelectBox>
            </div>
            <div className="text_input_wrap">
              <div className="box">
                <p className="box_title">Name&nbsp;<i className="require">*</i></p>
                <InputBox
                  placeholder="성함을 입력해주세요."
                  value={applyData.name}
                  maxLength={30}
                  onChange={(e) => {
                    if (e.target.value.length > 30) e.target.value = e.target.value.slice(0, -1);
                    handleInputData("name", e.target.value)
                  }}
                />
              </div>
              <div className="box">
                <p className="box_title">Phone number&nbsp;<i className="require">*</i></p>
                <InputBox
                  placeholder="-를 제외하고 입력해 주세요."
                  value={applyData.phone}
                  onChange={(e) => {
                    if (isNaN(Number(e.nativeEvent.data)) || e.nativeEvent.data === ' ') return;
                    handleInputData("phone", e.target.value)
                  }}
                />
              </div>
              <div className="box">
                <p className="box_title">e-mail</p>
                <InputBox
                  value={applyData.email}
                  onChange={(e) => handleInputData("email", e.target.value)}
                />
              </div>
              <div className="box">
                <p className="box_title">Portfolio URL</p>
                <InputBox
                  value={applyData.portfolioUrl}
                  onChange={(e) => handleInputData("portfolioUrl", e.target.value)}
                />
              </div>
            </div>
            <div className="box textarea_box">
              <p className="box_title">Your Story</p>
              <TextBox
                width="100%"
                height={ isMobile ? '310px' : '400px' }
                placeholder="자유롭게 당신의 이야기를 작성해 주세요.&#13;&#10;(ex - 지원동기, 역량, 장단점 등) | 최대 1,000자"
                value={applyData.desc}
                currentLength={applyData.desc.length}
                maxLength={1000}
                onChange={(e) => handleInputData("desc", e.target.value)}
              ></TextBox>
            </div>
            <div className="box input_file_box">
              <p className="box_title">Attached file</p>
            <InputFile
              className="file_input_wrap box"
              onChange={(files) => {
                handleInputData("file", files ? files[0] : files);
              }}
              initFile={initFile}
              setInitFile={setInitFIle}
            ></InputFile>
            </div>
            <PrivacyCheckWrap
              className="privacy_wrap"
              checked={applyData.agree}
              onClickCheck={(e) => handleInputData("agree", e.target.checked)}
              onClickMore={() => setPrivacyModalShow(!privacyModalShow)}
            />
            <Captcha
              className="box captcha_box"
              captcha={captcha}
              handleCaptcha={handleCaptcha}
            />

            {applyDataValid
              ? <WhiteButton
                  type="button"
                  className="apply_send_button on"
                  onClick={handleSendData}
              >
                Send
              </WhiteButton>
              : <WhiteButton
                  type="button"
                  className="apply_send_button on"
              >
                Send
              </WhiteButton>}
          </div>
        </div>
      </ApplyDrawer>
      {privacyModalShow ? (
        <PrivacyInfoModal
          closeModal={closeModal}
        ></PrivacyInfoModal>
      ) : (
        <></>
      )}
      {alertModalShow ? (
        <AlertModal
          closeModal={closeModal}
        >
          <p>이너뷰에 지원해 주셔서 감사합니다.</p>
          <p>담당자 검토 후 빠른 시일 내로<br/>회신 드리겠습니다.</p>
        </AlertModal>
      ) : (
        <></>
      )}
      <RecruitCont>

        <div className="banner">
          <div className="banner_inner">
            <h2 data-aos="fade-up">
              <span>We are Innerview</span>
              새로운 경험을 위한<br/>
              <b>끝없는 여정</b>
            </h2>
            <p data-aos="fade-up" data-aos-delay={SCROLL_DELAY*3}>
              <b>이너뷰다움 -</b><br/><br className="mo_br"/>
              고객과 커뮤니케이션을 통해 불분명한 것을 명확하게 해주는 사람<br/><br className="mo_br"/>
              항상 넘치는 에너지와 크리에이티브함을 갖춘 사람<br/><br className="mo_br"/>
              끊임없는 호기심으로 계속해서 탐구하여 자기 분야에 전문성을 다져나가는 사람<br/><br/>
              이너뷰다움을 추구하는 당신.<br/>
              앞으로도 계속될 ‘새로운 경험을 위한 끝없는 여정’에 합류해주세요.
            </p>
          </div>
        </div>

        <FlowTextContainer>
          <div className="flow_text_wrapper">
            <div className="flow_text_list">
              {flowTextArr1.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
              {flowTextArr1.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
          <div className="flow_text_wrapper">
            <div className="flow_text_list">
              {flowTextArr2.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
              {flowTextArr2.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
          <div className="flow_text_wrapper">
            <div className="flow_text_list">
              {flowTextArr3.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
              {flowTextArr3.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
          <div className="flow_text_wrapper">
            <div className="flow_text_list">
              {flowTextArr4.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
              {flowTextArr4.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
          <div className="flow_text_wrapper">
            <div className="flow_text_list">
              {flowTextArr5.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
              {flowTextArr5.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
          <div className="flow_text_wrapper">
            <div className="flow_text_list">
              {flowTextArr6.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
              {flowTextArr6.map((item, index) => (
                <div
                  key={item.title}
                  className={`flow_text_item ${item.point ? 'point' : ''}`}
                >
                  {item.title}
                </div>
              ))}
            </div>
          </div>
        </FlowTextContainer>

        <div className="content_wrapper recruit_wrapper">
          <ContentTitle
            subtitle="We're hiring"
          >
            이너뷰다운 당신<br/>
            <b>바로, 지금</b>
          </ContentTitle>
          <div className="recruit_list">
            {hiring.isLoading ? <></> :
                hiring.data.map((item, index) => (
                <div
                  className={"recruit_item" + (toggleState[index] ? " on" : "")}
                  key={index}
                >
                  <div
                    className="position"
                    onClick={() => {
                      let toggleControlArr = [false, false, false, false];
                      toggleControlArr[index] = !toggleState[index];
                      setToggleState(toggleControlArr);
                    }}
                  >
                    <h3 className="position_title">
                      {item.title}
                    </h3>
                    <div className="position_content">
                      <span className="career">신입/경력</span>
                      <span className="hiring_badge">Now Hiring</span>
                      <img className="hiring_arr_img" src="/img/arr_hiring.svg" alt="대각선 화살표" />
                    </div>
                  </div>
                  <div
                    className="qualification"
                    //ref={qualRef.current[index]}
                    //style={{height: qualRef.current[index].current?.clientHeight}}
                  >
                    <div className="content">
                      <div>
                        <p>담당업무</p>
                        <ul>
                          {item.Responsibilities.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                      <div>
                        <p>자격요건</p>
                        <ul>
                          {item.qualifications.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className="content_wrapper culture_wrapper">
          <ContentTitle
            subtitle="이너뷰는 이렇게 일해요"
          >
            INNERVIEW Culture
          </ContentTitle>
          <ul className="culture_list">
            {
              cultureList.map((item, index) => (
                <li
                  className="culture_item"
                  key={index}
                >
                  <div className="culture_item_inner">
                    <div className="icon_box">
                      <img
                        src={item.icon}
                        alt={item.title}
                      />
                    </div>
                    <span className="culture_title">{item.title}</span>
                    <p className="culture_description">{item.description}</p>
                  </div>
                </li>
              ))
            }

          </ul>
        </div>
      </RecruitCont>
      <FloatingRotateButton
        imgSrc="/img/text_apply_w.svg"
        imgAlt="이력서 작성하기 버튼"
        onClick={() => setDrawerOpen(!drawerOpen)}
        footerObs={footerRef}
      />
    </>
  );
}

export default Recruit;