export const HOST_NAME = "https://admin.innerviewit.com";

export const API_ROUTES = {
  getCategory: "/api/category",
  getExperience: "/api/experience/mainList",
  getExperienceDetail: "/api/experience/detailDesc",

  getMain: "/api/home/mainList",
  getBadge: "/api/about/badgeList",

  getLocation: "/api/contact/location",

  getService: "/api/request/serviceList",
  getProject: "/api/request/projectList",
  getHowToKnow: "/api/request/howToKnowList",
  getSend: "/api/request/send",

  getHiring: "/api/recruit/hiringList",

  postApplySend: "/api/apply/send",
  postRequestSend: "/api/request/send",
  downloadBrochure: "/api/download/brochure",
};

export const COMPANY_LOGO = "/img/iv_logo.svg";

export const LOCATION_MARKER = "/img/location_marker.png";

export const MOBILE_WIDTH = 750;
export const TABLET_WIDTH = 1100;

export const INPUT_HEIGHT = '6rem';
export const INPUT_HEIGHT_MOBILE = '59px';

export const SCROLL_DURATION = 1200;
export const SCROLL_DELAY = 100;
export const SCROLL_OFFSET = 200;

export const CompanyInfoObj = {
  address: '서울시 서초구 방배중앙로30, 302호 이너뷰',
  tel: '02 . 872 . 5496',
  email: 'sammrina@innerviewit.com',
  howto: '사당역 14번 출구로 나와 직진하면 보이는 세븐일레븐 건물 5층에 위치'
};
