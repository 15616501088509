import React from "react";
import ExperienceNavbar from "../../components/ExperienceNavbar";
import ExperienceList from "../../components/ExperienceList";

const Experience = () => {
  return (
    <section>
      <ExperienceNavbar/>
      <ExperienceList/>
    </section>
  );
}

export default Experience;
