import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { INPUT_HEIGHT, INPUT_HEIGHT_MOBILE, MOBILE_WIDTH } from "../../constants";
import { useMediaQuery } from "react-responsive";

const SelectMo = styled.select`
  position: relative;
  background: var(--black3) url(/img/arr_down.svg) center right 20px/20px no-repeat;
  font-size: 16px;
  font-weight: var(--fw_medium);
  line-height: 1.7;
  transform: scale(0.8125);
  transform-origin: left top;
  width: 123.07% !important;
  height: ${INPUT_HEIGHT_MOBILE};
  margin-right: -23.07%;
  margin-bottom: -11px;
  padding: 0 20px;

  &[data-value=""] {
    color: var(--gray5);
  }
`;

const SelectOption = styled.option``;

const SelectPC = styled.div`
  position: relative;
  width: 100%;
  height: ${INPUT_HEIGHT};
  padding: 0 1.8rem 0 1.6rem;
  background: var(--black3);
  cursor: pointer;

  p {
    font-size: 1.8rem;
    font-weight: var(--fw_medium);
    line-height: 1.7;
  }

  li {
    font-size: 1.6rem;
    font-weight: var(--fw_thin);
    line-height: 1.7;
    color: var(--gray1);
  }

  .select_label_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .img_select_arr {
    &[data-rotate="true"] {
      transform: rotate(180deg);
    }
  }
`;

const SelectLabel = styled.p`
  flex: 1 1 auto;
  padding-right: 20px;

  &[value=""] {
    color: var(--gray5);
  }
`;

const SelectOptionUl = styled.ul`
  display: ${(props) => props.show ? '' : 'none'};
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 1px;
  background: var(--white1);
  border: 1px solid var(--gray2);
`;

const SelectOptionLi = styled.li`
  padding: 9px 12px;
  border-bottom: 1px solid var(--gray2);

  &:last-child {
    border-bottom: 0 none;
  }

  &:hover {
    background: var(--main1);
  }
`;

const SelectBox = ({
  optionList,
  placeholder,
  setData,
  initSelect,
  setInitSelect,
}) => {
  const [dataValue, setDataValue] = useState('');
  const [currentValue, setCurrentValue] = useState(placeholder);
  const [isShowOptions, setIsShowOptions] = useState(false);

  // MO
  const handlePlaceholder = (e) => {
    if(e.target.value){
      setDataValue(e.target.value);
      if (setData) setData(e.target.value);
    }
  };

  // PC
  const handleSelect = (e) => {
    setCurrentValue(e.target.getAttribute('value'));
    if (setData) setData(e.target.getAttribute('value'));
  };

  useEffect(() => {
    if (initSelect) {
      setCurrentValue('');
      setDataValue('');
      setData('');
      setInitSelect(false);
    }
  }, [initSelect]);

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  return (
    <>
      {isMobile ? (
        <SelectMo
          defaultValue=""
          data-value={dataValue}
          onChange={handlePlaceholder}
        >
          <SelectOption
            value=""
            disabled
            hidden
          >
            {placeholder}
          </SelectOption>
          {
            optionList.map((item, index) => (
              <SelectOption
                key={item}
                value={item}
              >
                {item}
              </SelectOption>
            ))
          }
        </SelectMo>
      ) : (
        <SelectPC
          data-value={currentValue !== placeholder ? currentValue : ''}
          onClick={() => setIsShowOptions(prev => !prev)}
        >
          <div className="select_label_wrap">
            <SelectLabel
              value={currentValue !== placeholder ? currentValue : ''}
            >
              {currentValue}
            </SelectLabel>
            <img
              className="img_select_arr"
              src="/img/arr_down.svg"
              alt="화살표"
              data-rotate={isShowOptions}
            />
          </div>
          <SelectOptionUl
            show={isShowOptions}
          >
            {
              optionList.map((item, index) => (
                <SelectOptionLi
                  key={item}
                  value={item}
                  onClick={handleSelect}
                >
                  {item}
                </SelectOptionLi>
              ))
            }
          </SelectOptionUl>
        </SelectPC>
      )}
    </>
  );
}

export default SelectBox;
