import React from "react";
import styled from "styled-components";
import { MOBILE_WIDTH } from "../constants";
import Button from "../components/button/Button";
import Modal from "./Modal";

const StyledModal = styled(Modal)`
  .modal_body {
    width: 500px;
    min-height: 200px;
    background-color: var(--black1);
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.25);

    .modal_cont {
      padding: 40px 16px;

      .modal_text {
        text-align: center;

        p {
          font-size: 16px;
          font-weight: var(--fw_500);
          line-height: 1.7;
        }

        p:nth-child(n+2) {
          margin-top: 8px;
        }
      }

      .confirm_btn {
        display: block;
        margin: 24px auto 0;
        padding: 16px 72px;
        text-align: center;
        font-size: 16px;
        font-weight: var(--fw_500);
        color: var(--main1);
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    .modal_body {
      width: 330px;

      .close_btn {
        top: 16px;
        right: 16px;
      }

      .modal_cont {
        .modal_text {
          p {
            font-size: 14px;
          }
        }

        .confirm_btn {
          padding: 14px 60px;
          font-size: 14px;
        }
      }
    }
  }
`;

const AlertModal = ({
  children,
  className,
  closeModal
}) => {

  return (
    <StyledModal
      className={className}
      closeModal={closeModal}
    >
      <div className="modal_cont">
        <div className="modal_text">
          {children}
        </div>
        <Button
          className="modal_close_button confirm_btn"
          border={{
            radius: '40px',
            color: 'var(--white1)',
            width: '1px'
          }}
        >
          확인
        </Button>
      </div>
    </StyledModal>
  );
};

export default AlertModal;