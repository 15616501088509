import React from "react";
import styled from "styled-components";
import InputBox from "./form/InputBox";
import Button from "./button/Button";
import { MOBILE_WIDTH } from "../constants";

const PrivacyCheckWrapCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: align-items;

  input[type="checkbox"] {
    flex: 0 0 auto;
  }

  label {
    font-size: 1.8rem;
    padding-left: 12px;

    span {
      color: var(--main2);
    }
  }

  .privacy_more_button {
    padding: 4px 0;
    margin-left: 12px;
    font-size: 1.4rem;
    font-weight: var(--fw_medium);
    color: #8F8F8F;
    text-decoration: underline;
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    input[type="checkbox"] {
      width: 16px;
      height: 16px;
    }
    
    label {
      padding-left: 8px;
      font-size: 14px;
    }

    .privacy_more_button {
      padding: 2px 0;
      margin-left: 5px;
      font-size: 12px;
    }
  }
`;

const PrivacyCheckWrap = ({
  labelText,
  onClickCheck,
  onClickMore,
  checked,
  className,
}) => {
  return (
    <PrivacyCheckWrapCont
      className={className}
    >
      <InputBox
        type="checkbox"
        id="privacy_checkbox"
        name="privacy_checkbox"
        className="privacy_checkbox_input"
        onClick={onClickCheck}
        checked={checked}
        readOnly={true}
        labelText={
          labelText || 
          <>
            <span>&#40;필수&#41;</span>&nbsp;개인정보처리방침에 동의합니다.
          </>
        }
      />
      <Button
        className="privacy_more_button"
        onClick={onClickMore}
      >
        내용보기
      </Button>
    </PrivacyCheckWrapCont>
  );
}

export default PrivacyCheckWrap;
