export const makePhoneFormat = (phone) => {
    const regexPhone = (phone) => {
        const regex = /^\d{3}-\d{4}-\d{4}$/;
        return regex.test(phone);
    }

    if (!phone) return "";
    if (phone.length > 13) return phone.slice(0, -1);
    if (regexPhone(phone)) return phone;
    if (phone.length < 11 && !regexPhone(phone)) return phone;
    if (phone.length > 11 && !regexPhone(phone)) return phone.replaceAll("-", "");
    return phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
}

const iteration = (phone, lengthArray, typeArray, regexArray) => {
    for (let i = 0; i < 4; i++) {
        const length = lengthArray[i];
        if (phone.length > length) return phone.slice(0, -1);
        const type = typeArray[i];
        const regex = regexArray[i];
        for (let j = 0; j < type.length; j++) {
            const start = type[j];
            if (phone.startsWith(start)) {
                return phone.replaceAll('-','').replace(regex, "$1-$2-$3");
            }
        }
    }
    return phone;
}
export const makePhoneFormatExtension = (phone) => {
    const typeA = ["0502", "0503", "0504", "0505", "0506", "0507", "0508"]; // 안심번호
    const typeB = ["031", "032", "033", "041", "042", "043", "044", "051", "052", "053", "054", "055", "061", "062", "063", "064"] // 지역번호
    const typeC = ["070", "010"] // 인터넷전화, 휴대폰
    const typeD = ["02"]; // 서울지역번호

    const lengthArray = [14,13,13,12];
    const typeArray = [typeA, typeB, typeC, typeD];
    const regexArray = [/(\d{4})(\d{4})(\d{4})/, /(\d{3})(\d{3,4})(\d{4})/, /(\d{3})(\d{4})(\d{4})/, /(\d{2})(\d{3,4})(\d{4})/];
    return iteration(phone, lengthArray,typeArray, regexArray);
}