import React, { useEffect } from "react";
import styled from "styled-components";
import { MOBILE_WIDTH } from "../constants";

const ModalCont = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: calc(var(--header_index) + 1);

  .modal_background {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal_body {
      position: relative;

      .close_btn {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 26px;
        height: 26px;

        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          content:'';
          width:23px;
          height: 1px;
          transform: translate(-50%, -50%) rotate(135deg);
          background-color: var(--white1);
        }
  
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          content:'';
          width: 23px;
          height: 1px;
          transform: translate(-50%, -50%) rotate(45deg);
          background-color: var(--white1);
        }
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    .modal_background {
      .modal_body {
        .close_btn {
          top: 17px;
          right: 17px;
        }
      }
    }
  }
`;

const Modal = ({
  className,
  children,
  closeModal
}) => {

  const handleClose = (e) => {
    if(closeModal && (e.target === e.currentTarget || e.target.classList.contains('modal_close_button'))) {
      closeModal();
    }
  }

  useEffect(() => {
    document.body.style.cssText = `
      position: fixed; 
      top: -${window.scrollY}px;
      overflow-y: scroll;
      width: 100%;`;

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };
  }, []);
  
  return (
    <ModalCont
      className={className}
    >
      <div
        className="modal_background"
        onClick={handleClose}
      >
        <div className="modal_body">
          {children}
          <button
            type="button"
            className="modal_close_button close_btn"
          ></button>
        </div>
      </div>
    </ModalCont>
  );
}

export default Modal;