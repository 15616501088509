import React, {useEffect} from "react";
import styled from "styled-components";
import {LoadCanvasTemplate, loadCaptchaEnginge} from "react-simple-captcha";
import InputBox from "./form/InputBox";
import { MOBILE_WIDTH } from "../constants";

const CaptchaCont = styled.div`
    > div {
        &:first-child {
            display: flex;
            align-items: center;

            > * {
                flex: 0 0 auto;
            }

            canvas {
                width: 187px;
            }
        }
    }

    #reload_href {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        margin-left: 16px;

        &::before {
            content: '';
            display: inline-block;
            width: 40px;
            height: 40px;
            padding-bottom: 4px;
            background: url(/img/reload_w.svg) center/100% auto no-repeat;
        }
    }

    .captcha_input_wrap {
        margin-top: 4rem;

        input {
            width: 33rem;
        }
    }

    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        > div {
            &:first-child {
                display: flex;
                align-items: center;

                canvas {
                    width: 164px;
                }
            }
        }

        #reload_href {
            font-size: 11px;
            margin-left: 12px;

            &::before {
                width: 24px;
                height: 24px;
                margin-bottom: 8px;
            }
        }

        .captcha_input_wrap {
            margin-top: 16px;

            input {
                width: 100%;
            }
        }
    }
`;

const Captcha = ({captcha, handleCaptcha, className}) => {
    // Captcha
    useEffect(() => {
        loadCaptchaEnginge(4);
    }, [])

    return (
        <CaptchaCont className={className}>
            <LoadCanvasTemplate
                reloadText="새로고침"
                reloadColor="#8f8f8f"
            />
            <div className="captcha_input_wrap">
                <InputBox
                    placeholder="보안 문자를 입력해 주세요."
                    id="user_captcha_input"
                    className="user_captcha_input"
                    name="user_captcha_input"
                    type="text"
                    value={captcha}
                    onChange={handleCaptcha}
                />
            </div>
        </CaptchaCont>
    );
};

export default Captcha;