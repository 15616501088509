import React from "react";
import styled from "styled-components";
import { INPUT_HEIGHT, INPUT_HEIGHT_MOBILE, MOBILE_WIDTH } from "../../constants";

const InputCont = styled.div`
  display: flex;
  align-items: center;

  > * {
    flex: 0 1 auto;
  }
`;

const Input = styled.input`
  &[type] {
    ${ ({ width }) => `
      ${ width ? `width: ${ width }` : 'auto' };
    ` };
    height: ${INPUT_HEIGHT};
    padding: 0 1.6rem;
    font-size: 1.8rem;
    font-weight: var(--fw_medium);
    line-height: 1.7;
    background: var(--black3);
    border: 1px solid var(--black3);
    border-radius: 0;

    &::placeholder {
      color: var(--gray5);
    }

    &::-webkit-input-placeholder {
      color: var(--gray5);
    }

    &:-ms-input-placeholder {
      color: var(--gray5);
    }

    &:focus {
      border-color: var(--gray4);
    }

    // step 감추기
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // step 감추기
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &[type="checkbox"],
  &[type="radio"] {
    width: 2rem;
    height: 2rem;
    padding: 0;
    cursor: pointer;
    border: 1px solid var(--white1);
  }

  &[type="checkbox"]:checked {
    background: var(--main1) url(/img/checkbox_checked.svg) center/cover no-repeat;
  }

  @media screen and (max-width: ${ MOBILE_WIDTH }px) {
    &:not([type="checkbox"], [type="radio"]) {
      height: ${INPUT_HEIGHT_MOBILE};
      font-size: 16px;
      transform: scale(0.8125);
      transform-origin: left top;
      width: 123.07% !important;
      margin-right: -23.07%;
      margin-bottom: -11px;
      padding: 0 20px;
    }
  }
`;

const InputBox = React.memo(React.forwardRef(({
    id, className, disabled, readOnly, value, maxLength, name = '', placeholder, type = 'text', labelText, src, alt,
    width = 'auto', ...props
}, ref) => {

    return (<>
        { type === "checkbox" || type === "radio" ? (<InputCont
            className={ className }
            data-type={ type }
        >
            <Input
                ref={ ref }
                type={ type }
                id={ id }
                disabled={ disabled }
                readOnly={ readOnly }
                value={ value }
                name={ name }
                { ...props }
            />
            {/* 하위 라벨 이미지 및 텍스트가 있을 경우 */ }
            { labelText && src && (<label
                htmlFor={ id }
                name={ name }
                isImg="true" // 단순 구분값
            >
                <img
                    src={ src }
                    alt={ alt }
                />
                { labelText }
            </label>) }
            { labelText && !src && (<label
                htmlFor={ id }
                name={ name }
            >
                { labelText }
            </label>) }
        </InputCont>) : (<Input
            ref={ ref }
            type={ type }
            id={ id }
            className={ className }
            disabled={ disabled }
            readOnly={ readOnly }
            value={ value }
            name={ name }
            placeholder={ placeholder }
            maxLength={ maxLength }
            width={ width }
            { ...props }
        />) }
    </>);
}));

export default InputBox;