import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { MOBILE_WIDTH } from "../../constants";
import Button from "./Button";
import { useLocation, useNavigate } from "react-router-dom";

const AniRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  0% {
    transform: rotate(-360deg);
  }
`;

const FloatingRotateButtonCont = styled(Button)`
  position: fixed;
  z-index: calc(var(--navbar_index) - 1);
  right: 8rem;
  bottom: 8rem;
  width: 11rem;
  height: 11rem;
  padding: 0.9rem;
  border-radius: 50%;
  border: 2px solid #fff;
  background: linear-gradient(95deg, rgba(214, 226, 255, 0.3) 13.48%, rgba(129, 164, 255, 0.3) 83.07%);
  transition: border var(--trans_time);

  &.fixed {
    position: absolute;
  }

  &::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: linear-gradient(95deg, rgb(214, 226, 255) 13.48%, rgb(129, 164, 255) 83.07%);
    opacity: 0;
    transition: var(--trans_time);
    border-radius: 50%;
  }

  img {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    animation: ${AniRotate} 7s linear infinite;
    transition: filter var(--trans_time);
  }

  &:hover {
    border-color: transparent;

    &::after {
      opacity: 1;
    }

    img {
      filter: invert(1);
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    right: 24px;
    bottom: 32px;
    width: 80px;
    height: 80px;
    padding: 7px;
    border-width: 1px;

    img {
      animation-duration: 8s;
    }
  }
`;


const FloatingRotateButton = ({
  className = '',
  to,
  imgSrc,
  imgAlt,
  onClick,
  footerObs,
}) => {

  const navigate = useNavigate();
  const [isFixed, setIsFixed] = useState(false);
  const location = useLocation();

  // const buttonFixedFunc = () => {
  //   let sectionHeight = document.querySelector('section').offsetHeight;
  //   let windowHeight = window.innerHeight;
  //   let scrollTop = document.querySelector('html').scrollTop;

  //   if(sectionHeight - scrollTop < windowHeight){
  //     setIsFixed(true);
  //   }else{
  //     setIsFixed(false);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener('scroll', buttonFixedFunc);
  //   return () => window.removeEventListener('scroll', buttonFixedFunc);
  // }, []);

    useEffect(() => {
      if (!footerObs?.current) return;

      const floatingObs = new IntersectionObserver((ent, obs) => {
          ent.forEach((e) => {
              e.isIntersecting === true ? (
                setIsFixed(true)
              ) : (
                setIsFixed(false)
              )
          });
      });
      floatingObs.observe(footerObs?.current);
    }, [footerObs, location]);

  return (
    <FloatingRotateButtonCont
      className={`${className} ${isFixed ? 'fixed' : ''}`}
      onClick={to ? () => navigate(to) : onClick}
    >
      <img src={imgSrc} alt={imgAlt} />
    </FloatingRotateButtonCont>
  );
}

export default FloatingRotateButton;
