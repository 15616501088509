import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import styled from "styled-components";
import { responsiveFontMixin } from "../../styles/mixins";
import { MOBILE_WIDTH, TABLET_WIDTH } from "./../../constants";
import { useMediaQuery } from 'react-responsive';
import {Swiper, SwiperSlide} from "swiper/react";
import { Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import AOS from "aos";
import 'aos/dist/aos.css';
import { GetData } from "../../apis";

const DetailCont = styled.section`
  margin-top: 0;

  .desc_wrapper {
    padding-bottom: 7%;
  }

  .list_wrapper {
    padding-bottom: 8.5%;
  }

  @media screen and (max-width: 1366px) {
    .desc_wrapper {
      padding-bottom: 80px;
    }
    
    .list_wrapper {
      padding-bottom: 120px;
    }
  
  }
`;

const DetailBannerPC = styled.div`
[data-aos=fade-up] {
  transform: translate3d(0,55px,0);
}

  background-image: url(${ props => props.bg_img || ''});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .banner_content {
    width: 73%;
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .banner_title {
      text-align: center;
      flex: 0 1 auto;

      & > * {
        font-weight: var(--fw_700);
        ${responsiveFontMixin(110, 58, 1920, 360)};
        line-height: 1.1;
        white-space: pre-line;
      }
    }
    
    .banner_info {
      width: 100%;
      flex: 0 1 auto;
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      position: absolute;
      bottom: 0;

      & > div {
        margin-right: 60px;
        flex: 0 1 auto;

        p:first-child {
          font-weight: 600;
          font-size: 1.8rem;
          line-height: var(--lh_wide);
          margin-bottom: 4px;
        }
      }

      li {
        display: inline;

        &:not(:last-child)::after {
          content: ' / '
        }
      }
      
      .link_btn {
        margin-left: auto;
        margin-right: 0;
        flex: 0 0 auto;
        
        a {
          padding: 0.8rem 1.6rem;
          border-radius: 40px;
          background: rgba(255, 255, 255, 0.1);
          font-weight: var(--fw_500);
          font-size: 1.8rem;
          line-height: var(--lh_wide);
          display: inline-flex;
          justify-content: center;
          align-items: center;
  
          &::after {
            flex: 0 1 auto;
            content: '';
            width: 2.2rem;
            height: 0.6rem;
            display: block;
            margin-left: 0.8rem;
            background: url('/img/arr_go_website.svg') no-repeat center/contain;
          }
        }
        
      }
    }
  }
`;

const DetailBannerMO = styled.div`
  [data-aos=fade-up] {
    transform: translate3d(0,45px,0);
  }

  .banner_area {
    height: 100vh;
    background-image: url(${ props => props.bg_img || ''});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5.4%;
    position: relative;

    .banner_title {
      flex: 0 1 auto;

      & > * {
        font-weight: var(--fw_700);
        line-height: 1.1;
        letter-spacing: -0.01em;
        white-space: pre-line;
      }
      
      h2 {
        ${responsiveFontMixin(100,58,750,360)};

        span {
          ${responsiveFontMixin(58,26,750,360)};
        }
      }
    }

    .link_btn {
      margin-bottom: 14.4%;
      position: absolute;
      bottom: 0;
      flex: 0 0 auto;
      
      a {
        padding: 8px 16px;
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.1);
        font-weight: var(--fw_500);
        font-size: 16px;
        line-height: var(--lh_wide);
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &::after {
          flex: 0 1 auto;
          content: '';
          width: 16px;
          height: 4px;
          display: block;
          margin-left: 8px;
          background: url('/img/arr_go_website.svg') no-repeat center/contain;
        }
      }
    }

  }

  .banner_info {
    padding: 32px 20px;
    background-color: var(--white1);
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.07);

    * {
      color: #555;
      line-height: var(--lh_wide);
      font-size: 15px;
    }

    & > div {
      flex: 0 1 auto;
      display: flex;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      & > * {
        flex: 0 1 auto;
      }

      & > p:first-child {
        font-weight: 600;
        width: 25%;
      }
    }

    li {
      display: inline;

      &:not(:last-child)::after {
        content: ' / '
      }
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px){
    .banner_area {
      height: calc(var(--vh) * 100);
    }
  }
`

const ListCont = styled.div`
  margin: 0 4.2%;

  h3 {
    text-align: center;
    font-weight: var(--fw_500);
    font-size: 4rem;
    margin-bottom: 6.8%;
  }

  .list_btn {
    text-align: center;

    a {
      font-weight: var(--fw_700);
      font-size: 1.4rem;
      color: var(--main1);
      padding: 1.8rem 7.2rem;
      border: 1px solid var(--white1);
      border-radius: 4rem;
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    margin: 0 6.4%;

    h3 {
      text-align: left;
      font-size: 24px;
      margin-bottom: 32px;
    }

    .list_btn {
      a {
        font-size: 14px;
        padding: 14px 82px;
      }
    }
  }
`

const ProjectSwiper = styled(Swiper)`
  margin-bottom: 80px;

  .swiper-scrollbar {
    position: relative;
    left: auto;
    bottom: 0;
    width: 100%;
    margin-top: 60px;
    background: var(--gray1);
    border-radius: 2px;
    overflow: hidden;

    .swiper-scrollbar-drag {
      background: var(--white1);
      border-radius: 2px;
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    margin-bottom: 40px;
    
    .swiper-scrollbar {
      height: 2px;
      margin-top: 24px;
    }
  }
`

const ProjectSlide = styled(SwiperSlide)`
  & > a {
    display: block;
  }

  .img_area {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${props => props.bg_img || ''});
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15.8vw;
    margin-bottom: 2.4rem;

    img {
      flex: 0 1 auto;
      max-width: 60%;
    }
  }

  .info_area {
    p {
      color: var(--gray2);
      font-weight: var(--fw_500);

      &:first-child {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
      }
      
      &:last-child {
        font-size: 2rem;
      }
    }
  }

  @media screen and (max-width: ${TABLET_WIDTH}px) {
    .img_area {
      height: 20vw;
    }
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    .img_area {
      height: 30vw;
      margin-bottom: 16px;
    }

    .info_area p {
      &:first-child {
        font-size: 13px;
        margin-bottom: 6px;
      }

      &:last-child {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 374px) {
    .img_area {
      height: 55vw;
    }
  }
`

const Detail = () => {
  const location = useLocation();

  const isTablet = useMediaQuery({ query: `(max-width: ${TABLET_WIDTH}px)` });
  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });
  const isMobileNarrow = useMediaQuery({ query: `(max-width: 374px)` });

  const [isReadyData, setIsReadyData] = useState(0);

  const option = {
    params: location.pathname.split("/").pop(),
  }
  const { data, isLoading } = GetData("getExperienceDetail", option);

  const pcPerView = 4;
  const tabletPerView = 3;
  const mobilePerView = 2;
  const mobileNarrowPerView = 1;
  useEffect(() => {
    if(!!data){
      let addDataArr;
      if(isMobileNarrow){
        if(data.otherListData.length > mobileNarrowPerView){
          for(let i = data.otherListData.length - 1; i > mobileNarrowPerView - 1; i--){
            if(!data.otherListData[i].id){
              data.otherListData.splice(i, 1);
            }
          }
        }
      }else if(isMobile){
        if(data.otherListData.length < mobilePerView){
          addDataArr = new Array(mobilePerView - data.otherListData.length).fill({
            logoimg: '/img/iv_logo.svg',
            title: '이너뷰 로고'
          });
          data.otherListData.push(...addDataArr);
        }else if(data.otherListData.length > mobilePerView){
          for(let i = data.otherListData.length - 1; i > mobilePerView - 1; i--){
            if(!data.otherListData[i].id){
              data.otherListData.splice(i, 1);
            }
          }
        }
      }else if(isTablet){
        if(data.otherListData.length < tabletPerView){
          addDataArr = new Array(3 - data.otherListData.length).fill({
            logoimg: '/img/iv_logo.svg',
            title: '이너뷰 로고'
          });
          data.otherListData.push(...addDataArr);
        }else if(data.otherListData.length > tabletPerView){
          for(let i = data.otherListData.length - 1; i > tabletPerView - 1; i--){
            if(!data.otherListData[i].id){
              data.otherListData.splice(i, 1);
            }
          }
        }
      }else{
        if(data.otherListData.length < pcPerView){
          addDataArr = new Array(pcPerView - data.otherListData.length).fill({
            logoimg: '/img/iv_logo.svg',
            title: '이너뷰 로고'
          });
          data.otherListData.push(...addDataArr);
        }
      }
      setIsReadyData(isReadyData + 1);
    }
  }, [isLoading, isMobileNarrow, isMobile, isTablet]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 0,
      delay: 100,
    });
  },[]);

  return (
    <DetailCont>
      <div className="banner_wrapper">
        {isLoading ? (
            <></>
            ) : isMobile ? (
            <DetailBannerMO
                bg_img = {data.detailData.bannermoimg}
            >
              <div className="banner_area">
                <div className="banner_title">
                  <h2 data-aos="fade-up">
                    {data.detailData.title.indexOf('<br>') > -1 ? 
                      <>
                        {data.detailData.title.split('<br>')[0].trim()}<br/>
                        <span>{data.detailData.title.split('<br>')[1].trim()}</span>
                      </>
                       : data.detailData.title.indexOf('<br/>') > -1 ?
                        <>
                          {data.detailData.title.split('<br/>')[0].trim()}<br/>
                          <span>{data.detailData.title.split('<br/>')[1].trim()}</span>
                        </> 
                        : data.detailData.title
                    }
                  </h2>
                </div>
                {data.detailData.companyUrl ? (
                  <div className="link_btn" data-aos="fade-up" data-aos-delay="300">
                    <a href={data.detailData.companyUrl} target="_blank">Go to Website</a>
                  </div>
                ) : null}
              </div>
              <div className="banner_info">
                <div>
                  <p data-aos="fade-up">Client</p>
                  <p data-aos="fade-up">{data.detailData.client}</p>
                </div>
                <div>
                  <p data-aos="fade-up" data-aos-delay="200">Date</p>
                  <p data-aos="fade-up" data-aos-delay="200">{data.detailData.date}</p>
                </div>
                <div>
                  <p data-aos="fade-up" data-aos-delay="300">Service</p>
                  <ul data-aos="fade-up" data-aos-delay="300">
                    {data.detailData.service.map((list, key) => (
                        <li key={key}>{list}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </DetailBannerMO>
        ) : (
            <DetailBannerPC
                bg_img = {data.detailData.bannerpcimg}
            >
              <div className="banner_content">
                <div className="banner_title">
                  <h2 data-aos="fade-up">{data.detailData.title.replace(/(<br>|<br\/>)/g, '\n')}</h2>
                </div>
                <div className="banner_info">
                  <div>
                    <p data-aos="fade-up" data-aos-delay="200">Client</p>
                    <p data-aos="fade-up" data-aos-delay="300">{data.detailData.client}</p>
                  </div>
                  <div>
                    <p data-aos="fade-up" data-aos-delay="200">Date</p>
                    <p data-aos="fade-up" data-aos-delay="300">{data.detailData.date}</p>
                  </div>
                  <div>
                    <p data-aos="fade-up" data-aos-delay="200">Service</p>
                    <ul data-aos="fade-up" data-aos-delay="300">
                      {data.detailData.service.map((list, key) => (
                        <li key={key}>{list}</li>
                      ))}
                    </ul>
                  </div>
                    {data.detailData.companyUrl ? (
                      <div className="link_btn" data-aos="fade-up" data-aos-delay="200">
                        <a href={data.detailData.companyUrl} target="_blank">Go to Website</a>
                      </div>
                    ) : null}
                </div>
              </div>
            </DetailBannerPC>
        )}
      </div>
      <div className="desc_wrapper">
        {isLoading
          ? (
            <></>
          ) : isMobile
            ? data.detailData.descmoimg.map((src, key) => {
              return (
                <img src={src} alt="" key={key} loading="lazy" style={{width: '100%', minHeight:'150vh'}} />
              )
            }) : data.detailData.descpcimg.map((src, key) => {
                  return (
                    <img src={src} alt="" key={key} loading="lazy" style={{width: '100%', minHeight:'150vh'}} />
                  )
                }
              )
        }
      </div>
      <div className="list_wrapper">
        <ListCont>
          {isLoading
            ? <></>
            : !!data.otherListData && !!isReadyData
              ? (
                <>
                  <h3>OTHER PROJECT</h3>
                  <ProjectSwiper
                    modules={[Scrollbar]}
                    slidesPerView={1}
                    spaceBetween={12}
                    breakpoints={{
                      375: {
                        slidesPerView: 2,
                      },
                      [MOBILE_WIDTH + 1]: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                      },
                      [TABLET_WIDTH + 1]: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      }
                    }}
                    scrollbar={{
                      draggable: true
                    }}
                  >
                    {data.otherListData.map((item, key) => (
                      <ProjectSlide
                        key={key}
                        bg_img = {isMobile ? item.bannermoimg : item.bannerpcimg}
                      >
                        {item.id ? (
                          <NavLink
                            to={"/experience/detail/" + item.id}
                          >
                            <div className="img_area">
                              <img src={item.logoimg} alt={item.title} />
                            </div>
                            <div className="info_area">
                              <p>{item.categoryType}</p>
                              <p>{item.date}</p>
                            </div>
                          </NavLink>
                        ) : (
                          <div
                            style={{
                              cursor:'default'
                            }}
                          >
                            <div className="img_area">
                              <img src={item.logoimg} alt={item.title} />
                            </div>
                            <div className="info_area">
                              <p>{item.categoryType}</p>
                              <p>{item.date}</p>
                            </div>
                          </div>
                        )}
                      </ProjectSlide>
                    ))}
                  </ProjectSwiper>
                </>
              ) : null
          }
          {isLoading ? <></>
          : <div className="list_btn">
              <NavLink to={"/experience?category=" + data.detailData.categoryType}>List</NavLink>
            </div>
          }
        </ListCont>
      </div>
    </DetailCont>
  );
}

export default Detail;