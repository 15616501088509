import React, { useState } from "react";
import {NavLink, useLocation} from "react-router-dom";
import styled from "styled-components";
import { ellipsisMixin, responsiveFontMixin } from "../styles/mixins";
import { MOBILE_WIDTH, TABLET_WIDTH } from "../constants";
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Button from "./button/Button";
import {GetData} from "../apis";

const CATE_HEIGHT = 62;

const MobileCont = styled.div`
  position: sticky;
  z-index: 10;
  top: var(--header);
  background: var(--black1);

  .toggle_cate_button {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    width: 54px;
    height: 100%;
    background: var(--black1);
    text-align: left;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      width: 26px;
      height: 100%;
      background: linear-gradient(270deg, #272727 0%, rgba(39, 39, 39, 0) 100%);
    }
  }

  .cate_box {
    position: absolute;
    z-index: 10;
    top: calc(100% - ${CATE_HEIGHT}px - 1px);
    left: 0;
    width: 100%;
    background: var(--black1);

    &:not(.on) {
      display: none;
    }

    .cate_box_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: ${CATE_HEIGHT}px;
      padding: 0 24px;
      border-top: 1px solid var(--gray1);
      border-bottom: 1px solid var(--gray1);

      span {
        font-size: 14px;
        font-weight: var(--fw_medium);
      }

      .cate_close_button {
        position: relative;
        width: 26px;
        height: 26px;
  
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(135deg);
          width: 23px;
          height: 1px;
          background: var(--white1);
        }
  
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 23px;
          height: 1px;
          background: var(--white1);
        }
      }
    }

    .cate_box_list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 10px 0 22px;
    }
  }
`;

const CateList = styled(Swiper)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${CATE_HEIGHT}px;
  padding: 0 80px 0 24px;

  .swiper-wrapper {
    height: auto;
  }
`;

const CateItem = styled(SwiperSlide)`
  width: auto;

  a {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: var(--fw_bold);
    color: var(--gray1);
    border-radius: 50%;

    &.on {
      color: var(--black1);
      background: var(--main1);
    }
  }
`;

const CateBoxItem = styled.div`
  flex: 1 1 auto;
  padding: 0 16px;
  width: 50%;

  a {
    font-size: 14px;
    font-weight: var(--fw_bold);
    padding: 10px 8px;
    color: var(--gray1);
    ${ellipsisMixin('100%')};
    display: inline-block;

    &.on {
      color: var(--main1);
    }
  }
`;

const PCUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4.2% 4.7%;

  li {
    flex: 0 1 auto;
    margin: 0 1rem 2rem;

    a {
      position: relative;
      padding: 2.5rem 4rem;
      ${responsiveFontMixin(20, 13, 1920, 360)};
      font-weight: var(--fw_bold);
      color: #6b6b6b;
      transition: 0.3s;

      &:hover {
        color: var(--main1);
      }

      &::after {
        content: attr(data-menu);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2.5rem 6rem;
        border-radius: 50%;
        white-space: nowrap;
        background: var(--main1);
        color: var(--black1);
        opacity: 0;
        transition: opacity var(--trans_time);
      }

      &.on {
        &::after {
          opacity: 1;
        }
      }
    }
  }
`;

function ExperienceNavbar() {
  const [cateToggle, setCateToggle] = useState(false);
  const location = useLocation();
  const category = location.search.split("=")[1];

  const { data, isError, isLoading } = GetData("getCategory", { query: { pageName: "experience" } });

  const isMobile = useMediaQuery({ query: `(max-width: ${MOBILE_WIDTH}px)` });

  return (
    <>
      {/* 각 페이지와 매칭되는 NavLink에 className="on" 추가해야 함. */}

      {isMobile ? (
        <MobileCont>
          <CateList
            slidesPerView="auto"
            spaceBetween={0}
          >
            <CateItem
                key={-1}
            >
              <NavLink
                className={category === "All" ? "on" : ''}
                to="/experience/?category=All"
                data-menu="All"
              >
                All
              </NavLink>
            </CateItem>
            {isLoading ? <></> :
              data.map((data, index) => (
                <CateItem
                  key={index}
                >
                  <NavLink
                      className={decodeURI(category) === data.type ? "on" : ''}
                      to={`/experience/?category=${data.type}`}
                      data-menu={data.type}
                  >
                    {data.type}
                  </NavLink>
                </CateItem>
              ))
            }
          </CateList>
          <Button
            className="toggle_cate_button"
            onClick={() => setCateToggle(true)}
          >
            <img src="/img/experience_cate_dot.svg" alt="점 3개가 찍혀있는 이미지" />
          </Button>
          <div
            className={`cate_box ${cateToggle ? "on" : ""}`}
          >
            <div className="cate_box_top">
              <span>전체보기</span>
              <Button
                className="cate_close_button"
                onClick={() => setCateToggle(false)}
              ></Button>
            </div>
            <div className="cate_box_list">
              {isLoading ? <></> :
                data.map((data, index) => (
                  <CateBoxItem
                    key={index}
                  >
                    <NavLink
                      className={decodeURI(category) === data.type ? "on" : ''}
                      to={`/experience/?category=${data.type}`}
                      data-menu={data.type}
                    >
                      {data.type}
                    </NavLink>
                  </CateBoxItem>
                ))
              }
            </div>
          </div>
        </MobileCont>
      ) : (
        <PCUl>
          <li key={-1}>
            <NavLink
                className={category === "All" ? "on" : ''}
              to="/experience/?category=All"
              data-menu="All"
            >
              All
            </NavLink>
          </li>

          {isLoading ? <></> :
              data.map((data, index) => (
            <li key={index}>
              <NavLink
                  className={decodeURI(category) === data.type ? "on" : ''}
                  to={`/experience/?category=${data.type}`}
                  data-menu={data.type}
              >
                {data.type}
              </NavLink>
            </li>
          ))}
        </PCUl>
      )}
    </>
  );
}

export default ExperienceNavbar;
