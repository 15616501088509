// 화면 너비에 따라 폰트 크기를 유동적으로 조절
export const responsiveFontMixin = (maxSize, minSize, maxView, minView) => `
  font-size: calc(${minSize}px + (${maxSize} - ${minSize}) * ((100vw - ${minView}px) / (${maxView} - ${minView})));
  
  @media screen and (min-width: ${maxView}px) {
    font-size: ${maxSize}px;
  }

  @media screen and (max-width: ${minView}px) {
    font-size: ${minSize}px;
  }
`;

// 말줄임 한줄
export const ellipsisMixin = (width) => `
  width: ${width};
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// 말줄임 여러줄
export const maxLineEllipsisMixin = (fontSize, lh, linesToShow) => {
  const lineHeight = fontSize * lh;

  return `
    display: block; 
    display: -webkit-box;
    max-height: ${lineHeight * linesToShow}px;
    margin: 0 auto;
    font-size: ${fontSize}px;
    line-height: ${lineHeight}px;
    word-break: break-all;
    white-space: normal;
    -webkit-line-clamp: ${linesToShow};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
};

export const noScrollbarMixin = (overflowY) => {
  return `
    overflow-y: ${overflowY};
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `;
}
