import Footer from "../components/Footer";
import Header from "../components/Header";
import {Outlet, useLocation} from "react-router-dom";
import FloatingRotateButton from "../components/button/FloatingRotateButton";
import { useRef } from "react";

const Root = () => {
    const location = useLocation();
    window.scrollTo(0, 0);

    const footerRef = useRef(null);

    return (
        <>
            <Header
                className={(location.pathname === '/experience/') ? 'bg_black' : ''}
            />
            <div className="floating_wrapper">
                <Outlet context={{footerRef}} />
                {(location.pathname.indexOf('/recruit') > -1 || location.pathname.indexOf('/request') > -1 || location.pathname.indexOf('/contact') > -1) ? (
                    null
                ) : (
                    <FloatingRotateButton
                        to="/request"
                        imgSrc="/img/text_request_w.svg"
                        hoverImgSrc="/img/text_request_hover.svg"
                        imgAlt="프로젝트 문의하기 버튼"
                        footerObs={footerRef}
                    />
                )}
            </div>
            {location.pathname === '/' ? null : <Footer ref={footerRef} />}
        </>
    );
};
export default Root;
